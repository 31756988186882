@charset "UTF-8";
/*!
* www.KNACSS.com v7.0.6 (mai, 25 2018) @author: Alsacreations, Raphael Goetter
* Licence WTFPL http://www.wtfpl.net/
*/
/* ----------------------------- */
/* ==Table Of Content            */
/* ----------------------------- */
/*
1- Reboot (basic reset)
2- Libraries  :
  - Base
  - Print
  - Layout (alignment, modules, positionning)
  - Utilities (width and spacers helpers)
  - Responsive helpers
  - WordPress reset (disabled by default)
  - Grillade (Grid System)
3- Components :
  - Media object
  - Skip Links for accessibility
  - Tables
  - Forms
  - Buttons
  - Checkbox
  - Tabs
  - Tags
  - Badges
  - Alerts
*/
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: none !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bold; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/*# sourceMappingURL=bootstrap-reboot.css.map */
/* ----------------------------- */
/* ==Base (basic styles)         */
/* ----------------------------- */
/* switching to border-box model for all elements */
html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit;
  /* avoid min-width: auto on flex and grid children */
  min-width: 0;
  min-height: 0; }

html {
  /* set base font-size to equiv "10px", which is adapted to rem unit */
  font-size: 62.5%;
  /* IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
  font-size: calc(1em * 0.625); }

body {
  margin: 0;
  font-size: 1.4rem;
  background-color: #fff;
  color: #333030;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1.5; }
  @media (min-width: 576px) {
    body {
      font-size: 1.6rem; } }

/* Links */
a {
  color: #333; }

/* Headings */
h1, .h1-like {
  font-size: 2.8rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500; }
  @media (min-width: 576px) {
    h1, .h1-like {
      font-size: 3.2rem; } }

h2, .h2-like {
  font-size: 2.4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500; }
  @media (min-width: 576px) {
    h2, .h2-like {
      font-size: 2.8rem; } }

h3, .h3-like {
  font-size: 2rem;
  font-weight: 500; }
  @media (min-width: 576px) {
    h3, .h3-like {
      font-size: 2.4rem; } }

h4, .h4-like {
  font-size: 1.8rem;
  font-weight: 500; }
  @media (min-width: 576px) {
    h4, .h4-like {
      font-size: 2rem; } }

h5, .h5-like {
  font-size: 1.6rem;
  font-weight: 500; }
  @media (min-width: 576px) {
    h5, .h5-like {
      font-size: 1.8rem; } }

h6, .h6-like {
  font-size: 1.4rem;
  font-weight: 500; }
  @media (min-width: 576px) {
    h6, .h6-like {
      font-size: 1.6rem; } }

/* Vertical rythm */
h1,
h2,
h3,
h4,
h5,
h6,
dd {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p,
address,
ol,
ul,
dl,
blockquote,
pre {
  margin-top: 0;
  margin-bottom: 1rem; }

/* Avoid margins on nested elements */
li p,
li .p-like,
li ul,
li ol,
ol ol,
ul ul {
  margin-top: 0;
  margin-bottom: 0; }

/* Max values */
img,
table,
td,
blockquote,
code,
pre,
textarea,
input,
video,
svg {
  max-width: 100%; }

img {
  height: auto; }

/* Styling elements */
ul,
ol {
  padding-left: 2em; }

img {
  vertical-align: middle; }

em,
.italic,
address,
cite,
i,
var {
  font-style: italic; }

code,
kbd,
mark {
  border-radius: 2px; }

kbd {
  padding: 0 2px;
  border: 1px solid #999; }

pre {
  -moz-tab-size: 2;
    -o-tab-size: 2;
       tab-size: 2; }

code {
  padding: 2px 4px;
  background: rgba(0, 0, 0, 0.04);
  color: #b11; }

pre code {
  padding: 0;
  background: none;
  color: inherit;
  border-radius: 0; }

mark {
  padding: 2px 4px; }

sup,
sub {
  vertical-align: 0; }

sup {
  bottom: 1ex; }

sub {
  top: 0.5ex; }

blockquote {
  position: relative;
  padding-left: 3em;
  min-height: 2em; }

blockquote::before {
  content: "\201C";
  position: absolute;
  left: 0;
  top: 0;
  font-family: georgia, serif;
  font-size: 19rem;
  height: 7rem;
  line-height: .9;
  color: #e7e9ed; }

blockquote > footer {
  margin-top: .75em;
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.7); }
  blockquote > footer::before {
    content: "\2014 \0020"; }

q {
  font-style: normal; }

q,
.q {
  quotes: "“" "”" "‘" "’"; }
  q:lang(fr),
  .q:lang(fr) {
    quotes: "«\00a0" "\00a0»" "“" "”"; }

hr {
  display: block;
  clear: both;
  height: 1px;
  margin: 1em 0 2em;
  padding: 0;
  border: 0;
  color: #ccc;
  background-color: #ccc; }

blockquote,
figure {
  margin-left: 0;
  margin-right: 0; }

code,
pre,
samp,
kbd {
  white-space: pre-wrap;
  font-family: consolas, courier, monospace;
  line-height: normal; }

/* ----------------------------- */
/* ==Print (quick print reset)   */
/* ----------------------------- */
@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  body {
    width: auto;
    margin: auto;
    font-family: serif;
    font-size: 12pt; }
  p,
  .p-like,
  h1,
  .h1-like,
  h2,
  .h2-like,
  h3,
  .h3-like,
  h4,
  .h4-like,
  h5,
  .h5-like,
  h6,
  .h6-like,
  blockquote,
  label,
  ul,
  ol {
    color: #000;
    margin: auto; }
  .print {
    display: block; }
  .no-print {
    display: none; }
  /* no orphans, no widows */
  p,
  .p-like,
  blockquote {
    orphans: 3;
    widows: 3; }
  /* no breaks inside these elements */
  blockquote,
  ul,
  ol {
    page-break-inside: avoid; }
  /* page break before main headers
  h1,
  .h1-like {
    page-break-before: always;
  }
  */
  /* no breaks after these elements */
  h1,
  .h1-like,
  h2,
  .h2-like,
  h3,
  .h3-like,
  caption {
    page-break-after: avoid; }
  a {
    color: #000; }
  /* displaying URLs
  a[href]::after {
    content: " (" attr(href) ")";
  }
  */
  a[href^="javascript:"]::after,
  a[href^="#"]::after {
    content: ""; } }

/* ----------------------------- */
/* ==Global Micro Layout         */
/* ----------------------------- */
/* module, gains superpower "BFC" Block Formating Context */
.mod,
.bfc {
  overflow: hidden; }

/* blocks that need to be placed under floats */
.clear {
  clear: both; }

/* blocks that must contain floats */
.clearfix::after {
  content: "";
  display: table;
  clear: both;
  border-collapse: collapse; }

/* simple blocks alignment */
.left {
  margin-right: auto; }

.right {
  margin-left: auto; }

.center {
  margin-left: auto;
  margin-right: auto; }

/* text and contents alignment */
.txtleft {
  text-align: left; }

.txtright {
  text-align: right; }

.txtcenter {
  text-align: center; }

/* floating elements */
.fl {
  float: left; }

img.fl {
  margin-right: 1rem; }

.fr {
  float: right; }

img.fr {
  margin-left: 1rem; }

img.fl,
img.fr {
  margin-bottom: 0.5rem; }

/* inline-block */
.inbl {
  display: inline-block;
  vertical-align: top; }

/* flexbox layout
http://www.alsacreations.com/tuto/lire/1493-css3-flexbox-layout-module.html
*/
.flex-container, .flex-container--row,
.flex-row, .flex-container--column,
.flex-column, .flex-container--row-reverse,
.flex-row-reverse, .flex-container--column-reverse,
.flex-column-reverse,
.d-flex {
  display: flex;
  flex-wrap: wrap; }

.flex-container--row,
.flex-row {
  flex-direction: row; }

.flex-container--column,
.flex-column {
  flex-direction: column; }

.flex-container--row-reverse,
.flex-row-reverse {
  flex-direction: row-reverse;
  justify-content: flex-end; }

.flex-container--column-reverse,
.flex-column-reverse {
  flex-direction: column-reverse;
  justify-content: flex-end; }

.flex-item-fluid,
.item-fluid {
  flex: 1 1 0%; }

.flex-item-first,
.item-first {
  order: -1; }

.flex-item-medium,
.item-medium {
  order: 0; }

.flex-item-last,
.item-last {
  order: 1; }

.flex-item-center,
.item-center,
.mr-auto {
  margin: auto; }

/* ---------------------------------- */
/* ==Helpers                          */
/* ---------------------------------- */
/* Typo Helpers  */
/* ------------- */
.u-bold {
  font-weight: 700; }

.u-italic {
  font-style: italic; }

.u-normal {
  font-weight: normal;
  font-style: normal; }

.u-uppercase {
  text-transform: uppercase; }

.u-lowercase {
  text-transform: lowercase; }

.u-smaller {
  font-size: 0.6em; }

.u-small {
  font-size: 0.8em; }

.u-big {
  font-size: 1.2em; }

.u-bigger {
  font-size: 1.5em; }

.u-biggest {
  font-size: 2em; }

.u-txt-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto; }

.u-txt-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis; }

/* State Helpers */
/* ------------- */
/* invisible for all */
.is-hidden,
.js-hidden,
[hidden] {
  display: none; }

/* hidden but not for an assistive technology like a screen reader, Yahoo! method */
.visually-hidden, .tabs-content-item[aria-hidden="true"] {
  position: absolute !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important; }

.is-disabled,
.js-disabled,
[disabled],
.is-disabled ~ label,
[disabled] ~ label {
  opacity: 0.5;
  cursor: not-allowed !important;
  -webkit-filter: grayscale(1);
          filter: grayscale(1); }

ul.is-unstyled, ul.unstyled {
  list-style: none;
  padding-left: 0; }

.color--inverse {
  color: #fff; }

/* Width Helpers */
/* ------------- */
/* blocks widths (percentage and pixels) */
.w100 {
  width: 100%; }

.w95 {
  width: 95%; }

.w90 {
  width: 90%; }

.w85 {
  width: 85%; }

.w80 {
  width: 80%; }

.w75 {
  width: 75%; }

.w70 {
  width: 70%; }

.w65 {
  width: 65%; }

.w60 {
  width: 60%; }

.w55 {
  width: 55%; }

.w50 {
  width: 50%; }

.w45 {
  width: 45%; }

.w40 {
  width: 40%; }

.w35 {
  width: 35%; }

.w30 {
  width: 30%; }

.w25 {
  width: 25%; }

.w20 {
  width: 20%; }

.w15 {
  width: 15%; }

.w10 {
  width: 10%; }

.w5 {
  width: 5%; }

.w66 {
  width: calc(100% / 3 * 2); }

.w33 {
  width: calc(100% / 3); }

.wauto {
  width: auto; }

.w960p {
  width: 960px; }

.mw960p {
  max-width: 960px; }

.w1140p {
  width: 1140px; }

.mw1140p {
  max-width: 1140px; }

.w1000p {
  width: 1000px; }

.w950p {
  width: 950px; }

.w900p {
  width: 900px; }

.w850p {
  width: 850px; }

.w800p {
  width: 800px; }

.w750p {
  width: 750px; }

.w700p {
  width: 700px; }

.w650p {
  width: 650px; }

.w600p {
  width: 600px; }

.w550p {
  width: 550px; }

.w500p {
  width: 500px; }

.w450p {
  width: 450px; }

.w400p {
  width: 400px; }

.w350p {
  width: 350px; }

.w300p {
  width: 300px; }

.w250p {
  width: 250px; }

.w200p {
  width: 200px; }

.w150p {
  width: 150px; }

.w100p {
  width: 100px; }

.w50p {
  width: 50px; }

/* Spacing Helpers */
/* --------------- */
.man,
.ma0 {
  margin: 0; }

.pan,
.pa0 {
  padding: 0; }

.mas {
  margin: 1rem; }

.mam {
  margin: 2rem; }

.mal {
  margin: 4rem; }

.pas {
  padding: 1rem; }

.pam {
  padding: 2rem; }

.pal {
  padding: 4rem; }

.mtn,
.mt0 {
  margin-top: 0; }

.mts {
  margin-top: 1rem; }

.mtm {
  margin-top: 2rem; }

.mtl {
  margin-top: 4rem; }

.mrn,
.mr0 {
  margin-right: 0; }

.mrs {
  margin-right: 1rem; }

.mrm {
  margin-right: 2rem; }

.mrl {
  margin-right: 4rem; }

.mbn,
.mb0 {
  margin-bottom: 0; }

.mbs {
  margin-bottom: 1rem; }

.mbm {
  margin-bottom: 2rem; }

.mbl {
  margin-bottom: 4rem; }

.mln,
.ml0 {
  margin-left: 0; }

.mls {
  margin-left: 1rem; }

.mlm {
  margin-left: 2rem; }

.mll {
  margin-left: 4rem; }

.mauto {
  margin: auto; }

.mtauto {
  margin-top: auto; }

.mrauto {
  margin-right: auto; }

.mbauto {
  margin-bottom: auto; }

.mlauto {
  margin-left: auto; }

.ptn,
.pt0 {
  padding-top: 0; }

.pts {
  padding-top: 1rem; }

.ptm {
  padding-top: 2rem; }

.ptl {
  padding-top: 4rem; }

.prn,
.pr0 {
  padding-right: 0; }

.prs {
  padding-right: 1rem; }

.prm {
  padding-right: 2rem; }

.prl {
  padding-right: 4rem; }

.pbn,
.pb0 {
  padding-bottom: 0; }

.pbs {
  padding-bottom: 1rem; }

.pbm {
  padding-bottom: 2rem; }

.pbl {
  padding-bottom: 4rem; }

.pln,
.pl0 {
  padding-left: 0; }

.pls {
  padding-left: 1rem; }

.plm {
  padding-left: 2rem; }

.pll {
  padding-left: 4rem; }

/* -------------------------- */
/* ==Responsive helpers       */
/* -------------------------- */
/* large screens */
/* ------------- */
@media (min-width: 992px) {
  /* layouts for large screens */
  .large-hidden {
    display: none !important; }
  .large-visible {
    display: block !important; }
  .large-no-float {
    float: none; }
  .large-inbl {
    display: inline-block;
    float: none;
    vertical-align: top; }
  /* widths for large screens */
  .large-w25 {
    width: 25% !important; }
  .large-w33 {
    width: 33.333333% !important; }
  .large-w50 {
    width: 50% !important; }
  .large-w66 {
    width: 66.666666% !important; }
  .large-w75 {
    width: 75% !important; }
  .large-w100,
  .large-wauto {
    display: block !important;
    float: none !important;
    clear: none !important;
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 0; }
  /* margins for large screens */
  .large-man,
  .large-ma0 {
    margin: 0 !important; } }

/* medium screens */
/* -------------- */
@media (min-width: 768px) and (max-width: 991px) {
  /* layouts for medium screens */
  .medium-hidden {
    display: none !important; }
  .medium-visible {
    display: block !important; }
  .medium-no-float {
    float: none; }
  .medium-inbl {
    display: inline-block;
    float: none;
    vertical-align: top; }
  /* widths for medium screens */
  .medium-w25 {
    width: 25% !important; }
  .medium-w33 {
    width: 33.333333% !important; }
  .medium-w50 {
    width: 50% !important; }
  .medium-w66 {
    width: 66.666666% !important; }
  .medium-w75 {
    width: 75% !important; }
  .medium-w100,
  .medium-wauto {
    display: block !important;
    float: none !important;
    clear: none !important;
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 0; }
  /* margins for medium screens */
  .medium-man,
  .medium-ma0 {
    margin: 0 !important; } }

/* small screens */
/* ------------- */
@media (min-width: 576px) and (max-width: 767px) {
  /* layouts for small screens */
  .small-hidden {
    display: none !important; }
  .small-visible {
    display: block !important; }
  .small-no-float {
    float: none; }
  .small-inbl {
    display: inline-block;
    float: none;
    vertical-align: top; }
  /* widths for small screens */
  .small-w25 {
    width: 25% !important; }
  .small-w33 {
    width: 33.333333% !important; }
  .small-w50 {
    width: 50% !important; }
  .small-w66 {
    width: 66.666666% !important; }
  .small-w75 {
    width: 75% !important; }
  .small-w100,
  .small-wauto {
    display: block !important;
    float: none !important;
    clear: none !important;
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 0; }
  /* margins for small screens */
  .small-man,
  .small-ma0 {
    margin: 0 !important; }
  .small-pan,
  .small-pa0 {
    padding: 0 !important; } }

/* tiny screens */
/* ------------ */
@media (max-width: 575px) {
  /* quick small resolution reset */
  .mod,
  .col,
  fieldset {
    display: block !important;
    float: none !important;
    clear: none !important;
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 0; }
  /* layouts for tiny screens */
  .tiny-hidden {
    display: none !important; }
  .tiny-visible {
    display: block !important; }
  .tiny-no-float {
    float: none; }
  .tiny-inbl {
    display: inline-block;
    float: none;
    vertical-align: top; }
  /* widths for tiny screens */
  .tiny-w25 {
    width: 25% !important; }
  .tiny-w33 {
    width: 33.333333% !important; }
  .tiny-w50 {
    width: 50% !important; }
  .tiny-w66 {
    width: 66.666666% !important; }
  .tiny-w75 {
    width: 75% !important; }
  .tiny-w100,
  .tiny-wauto {
    display: block !important;
    float: none !important;
    clear: none !important;
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 0; }
  /* margins for tiny screens */
  .tiny-man,
  .tiny-ma0 {
    margin: 0 !important; }
  .tiny-pan,
  .tiny-pa0 {
    padding: 0 !important; } }

/* ---------------------------------- */
/* ==Grillade v6                      */
/* ---------------------------------- */
/* IMPORTANT : this is the KNACSS v6 old Grid System based on Flexbox */
/* You only need it for projects on older browsers (IE11-) */
@media (min-width: 576px) {
  [class*=" grid-"],
  [class^="grid-"] {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    [class*=" grid-"] > *,
    [class^="grid-"] > * {
      box-sizing: border-box;
      min-width: 0;
      min-height: 0; } }

@media (min-width: 576px) {
  .grid,
  .grid--reverse {
    display: flex; }
    .grid > *,
    .grid--reverse > * {
      flex: 1 1 0%;
      box-sizing: border-box;
      min-width: 0;
      min-height: 0; }
    .grid.has-gutter > * + *,
    .grid--reverse.has-gutter > * + * {
      margin-left: calc(1rem - 0.01px); }
    .grid.has-gutter-l > * + *,
    .grid--reverse.has-gutter-l > * + * {
      margin-left: calc(2rem - 0.01px); }
    .grid.has-gutter-xl > * + *,
    .grid--reverse.has-gutter-xl > * + * {
      margin-left: calc(4rem - 0.01px); } }

@media (min-width: 576px) {
  [class*="grid-2"] > * {
    width: calc(100% / 2 - 0.01px); }
  [class*="grid-2"].has-gutter {
    margin-right: -0.5rem;
    margin-left: -0.5rem; }
    [class*="grid-2"].has-gutter > * {
      width: calc(100% / 2 - 1rem - 0.01px);
      margin-right: 0.5rem;
      margin-left: 0.5rem; }
  [class*="grid-2"].has-gutter-l {
    margin-right: -1rem;
    margin-left: -1rem; }
    [class*="grid-2"].has-gutter-l > * {
      width: calc(100% / 2 - 2rem - 0.01px);
      margin-right: 1rem;
      margin-left: 1rem; }
  [class*="grid-2"].has-gutter-xl {
    margin-right: -2rem;
    margin-left: -2rem; }
    [class*="grid-2"].has-gutter-xl > * {
      width: calc(100% / 2 - 4rem - 0.01px);
      margin-right: 2rem;
      margin-left: 2rem; }
  [class*="grid-3"] > * {
    width: calc(100% / 3 - 0.01px); }
  [class*="grid-3"].has-gutter {
    margin-right: -0.5rem;
    margin-left: -0.5rem; }
    [class*="grid-3"].has-gutter > * {
      width: calc(100% / 3 - 1rem - 0.01px);
      margin-right: 0.5rem;
      margin-left: 0.5rem; }
  [class*="grid-3"].has-gutter-l {
    margin-right: -1rem;
    margin-left: -1rem; }
    [class*="grid-3"].has-gutter-l > * {
      width: calc(100% / 3 - 2rem - 0.01px);
      margin-right: 1rem;
      margin-left: 1rem; }
  [class*="grid-3"].has-gutter-xl {
    margin-right: -2rem;
    margin-left: -2rem; }
    [class*="grid-3"].has-gutter-xl > * {
      width: calc(100% / 3 - 4rem - 0.01px);
      margin-right: 2rem;
      margin-left: 2rem; }
  [class*="grid-4"] > * {
    width: calc(100% / 4 - 0.01px); }
  [class*="grid-4"].has-gutter {
    margin-right: -0.5rem;
    margin-left: -0.5rem; }
    [class*="grid-4"].has-gutter > * {
      width: calc(100% / 4 - 1rem - 0.01px);
      margin-right: 0.5rem;
      margin-left: 0.5rem; }
  [class*="grid-4"].has-gutter-l {
    margin-right: -1rem;
    margin-left: -1rem; }
    [class*="grid-4"].has-gutter-l > * {
      width: calc(100% / 4 - 2rem - 0.01px);
      margin-right: 1rem;
      margin-left: 1rem; }
  [class*="grid-4"].has-gutter-xl {
    margin-right: -2rem;
    margin-left: -2rem; }
    [class*="grid-4"].has-gutter-xl > * {
      width: calc(100% / 4 - 4rem - 0.01px);
      margin-right: 2rem;
      margin-left: 2rem; }
  [class*="grid-5"] > * {
    width: calc(100% / 5 - 0.01px); }
  [class*="grid-5"].has-gutter {
    margin-right: -0.5rem;
    margin-left: -0.5rem; }
    [class*="grid-5"].has-gutter > * {
      width: calc(100% / 5 - 1rem - 0.01px);
      margin-right: 0.5rem;
      margin-left: 0.5rem; }
  [class*="grid-5"].has-gutter-l {
    margin-right: -1rem;
    margin-left: -1rem; }
    [class*="grid-5"].has-gutter-l > * {
      width: calc(100% / 5 - 2rem - 0.01px);
      margin-right: 1rem;
      margin-left: 1rem; }
  [class*="grid-5"].has-gutter-xl {
    margin-right: -2rem;
    margin-left: -2rem; }
    [class*="grid-5"].has-gutter-xl > * {
      width: calc(100% / 5 - 4rem - 0.01px);
      margin-right: 2rem;
      margin-left: 2rem; }
  [class*="grid-6"] > * {
    width: calc(100% / 6 - 0.01px); }
  [class*="grid-6"].has-gutter {
    margin-right: -0.5rem;
    margin-left: -0.5rem; }
    [class*="grid-6"].has-gutter > * {
      width: calc(100% / 6 - 1rem - 0.01px);
      margin-right: 0.5rem;
      margin-left: 0.5rem; }
  [class*="grid-6"].has-gutter-l {
    margin-right: -1rem;
    margin-left: -1rem; }
    [class*="grid-6"].has-gutter-l > * {
      width: calc(100% / 6 - 2rem - 0.01px);
      margin-right: 1rem;
      margin-left: 1rem; }
  [class*="grid-6"].has-gutter-xl {
    margin-right: -2rem;
    margin-left: -2rem; }
    [class*="grid-6"].has-gutter-xl > * {
      width: calc(100% / 6 - 4rem - 0.01px);
      margin-right: 2rem;
      margin-left: 2rem; }
  [class*="grid-7"] > * {
    width: calc(100% / 7 - 0.01px); }
  [class*="grid-7"].has-gutter {
    margin-right: -0.5rem;
    margin-left: -0.5rem; }
    [class*="grid-7"].has-gutter > * {
      width: calc(100% / 7 - 1rem - 0.01px);
      margin-right: 0.5rem;
      margin-left: 0.5rem; }
  [class*="grid-7"].has-gutter-l {
    margin-right: -1rem;
    margin-left: -1rem; }
    [class*="grid-7"].has-gutter-l > * {
      width: calc(100% / 7 - 2rem - 0.01px);
      margin-right: 1rem;
      margin-left: 1rem; }
  [class*="grid-7"].has-gutter-xl {
    margin-right: -2rem;
    margin-left: -2rem; }
    [class*="grid-7"].has-gutter-xl > * {
      width: calc(100% / 7 - 4rem - 0.01px);
      margin-right: 2rem;
      margin-left: 2rem; }
  [class*="grid-8"] > * {
    width: calc(100% / 8 - 0.01px); }
  [class*="grid-8"].has-gutter {
    margin-right: -0.5rem;
    margin-left: -0.5rem; }
    [class*="grid-8"].has-gutter > * {
      width: calc(100% / 8 - 1rem - 0.01px);
      margin-right: 0.5rem;
      margin-left: 0.5rem; }
  [class*="grid-8"].has-gutter-l {
    margin-right: -1rem;
    margin-left: -1rem; }
    [class*="grid-8"].has-gutter-l > * {
      width: calc(100% / 8 - 2rem - 0.01px);
      margin-right: 1rem;
      margin-left: 1rem; }
  [class*="grid-8"].has-gutter-xl {
    margin-right: -2rem;
    margin-left: -2rem; }
    [class*="grid-8"].has-gutter-xl > * {
      width: calc(100% / 8 - 4rem - 0.01px);
      margin-right: 2rem;
      margin-left: 2rem; }
  [class*="grid-9"] > * {
    width: calc(100% / 9 - 0.01px); }
  [class*="grid-9"].has-gutter {
    margin-right: -0.5rem;
    margin-left: -0.5rem; }
    [class*="grid-9"].has-gutter > * {
      width: calc(100% / 9 - 1rem - 0.01px);
      margin-right: 0.5rem;
      margin-left: 0.5rem; }
  [class*="grid-9"].has-gutter-l {
    margin-right: -1rem;
    margin-left: -1rem; }
    [class*="grid-9"].has-gutter-l > * {
      width: calc(100% / 9 - 2rem - 0.01px);
      margin-right: 1rem;
      margin-left: 1rem; }
  [class*="grid-9"].has-gutter-xl {
    margin-right: -2rem;
    margin-left: -2rem; }
    [class*="grid-9"].has-gutter-xl > * {
      width: calc(100% / 9 - 4rem - 0.01px);
      margin-right: 2rem;
      margin-left: 2rem; }
  [class*="grid-10"] > * {
    width: calc(100% / 10 - 0.01px); }
  [class*="grid-10"].has-gutter {
    margin-right: -0.5rem;
    margin-left: -0.5rem; }
    [class*="grid-10"].has-gutter > * {
      width: calc(100% / 10 - 1rem - 0.01px);
      margin-right: 0.5rem;
      margin-left: 0.5rem; }
  [class*="grid-10"].has-gutter-l {
    margin-right: -1rem;
    margin-left: -1rem; }
    [class*="grid-10"].has-gutter-l > * {
      width: calc(100% / 10 - 2rem - 0.01px);
      margin-right: 1rem;
      margin-left: 1rem; }
  [class*="grid-10"].has-gutter-xl {
    margin-right: -2rem;
    margin-left: -2rem; }
    [class*="grid-10"].has-gutter-xl > * {
      width: calc(100% / 10 - 4rem - 0.01px);
      margin-right: 2rem;
      margin-left: 2rem; }
  [class*="grid-11"] > * {
    width: calc(100% / 11 - 0.01px); }
  [class*="grid-11"].has-gutter {
    margin-right: -0.5rem;
    margin-left: -0.5rem; }
    [class*="grid-11"].has-gutter > * {
      width: calc(100% / 11 - 1rem - 0.01px);
      margin-right: 0.5rem;
      margin-left: 0.5rem; }
  [class*="grid-11"].has-gutter-l {
    margin-right: -1rem;
    margin-left: -1rem; }
    [class*="grid-11"].has-gutter-l > * {
      width: calc(100% / 11 - 2rem - 0.01px);
      margin-right: 1rem;
      margin-left: 1rem; }
  [class*="grid-11"].has-gutter-xl {
    margin-right: -2rem;
    margin-left: -2rem; }
    [class*="grid-11"].has-gutter-xl > * {
      width: calc(100% / 11 - 4rem - 0.01px);
      margin-right: 2rem;
      margin-left: 2rem; }
  [class*="grid-12"] > * {
    width: calc(100% / 12 - 0.01px); }
  [class*="grid-12"].has-gutter {
    margin-right: -0.5rem;
    margin-left: -0.5rem; }
    [class*="grid-12"].has-gutter > * {
      width: calc(100% / 12 - 1rem - 0.01px);
      margin-right: 0.5rem;
      margin-left: 0.5rem; }
  [class*="grid-12"].has-gutter-l {
    margin-right: -1rem;
    margin-left: -1rem; }
    [class*="grid-12"].has-gutter-l > * {
      width: calc(100% / 12 - 2rem - 0.01px);
      margin-right: 1rem;
      margin-left: 1rem; }
  [class*="grid-12"].has-gutter-xl {
    margin-right: -2rem;
    margin-left: -2rem; }
    [class*="grid-12"].has-gutter-xl > * {
      width: calc(100% / 12 - 4rem - 0.01px);
      margin-right: 2rem;
      margin-left: 2rem; } }

.push {
  margin-left: auto !important; }

.pull {
  margin-right: auto !important; }

.item-first {
  order: -1; }

.item-last {
  order: 1; }

[class*="grid-"][class*="--reverse"] {
  flex-direction: row-reverse; }

@media (min-width: 576px) {
  .full {
    flex: 0 0 auto;
    width: calc(100% / 1 - 0.01px); }
  .has-gutter > .full {
    width: calc(100% / 1 - 1rem - 0.01px); }
  .has-gutter-l > .full {
    width: calc(100% / 1 - 2rem - 0.01px); }
  .has-gutter-xl > .full {
    width: calc(100% / 1 - 4rem - 0.01px); }
  .one-half {
    flex: 0 0 auto;
    width: calc(100% / 2 - 0.01px); }
  .has-gutter > .one-half {
    width: calc(100% / 2 - 1rem - 0.01px); }
  .has-gutter-l > .one-half {
    width: calc(100% / 2 - 2rem - 0.01px); }
  .has-gutter-xl > .one-half {
    width: calc(100% / 2 - 4rem - 0.01px); }
  .one-third {
    flex: 0 0 auto;
    width: calc(100% / 3 - 0.01px); }
  .has-gutter > .one-third {
    width: calc(100% / 3 - 1rem - 0.01px); }
  .has-gutter-l > .one-third {
    width: calc(100% / 3 - 2rem - 0.01px); }
  .has-gutter-xl > .one-third {
    width: calc(100% / 3 - 4rem - 0.01px); }
  .one-quarter {
    flex: 0 0 auto;
    width: calc(100% / 4 - 0.01px); }
  .has-gutter > .one-quarter {
    width: calc(100% / 4 - 1rem - 0.01px); }
  .has-gutter-l > .one-quarter {
    width: calc(100% / 4 - 2rem - 0.01px); }
  .has-gutter-xl > .one-quarter {
    width: calc(100% / 4 - 4rem - 0.01px); }
  .one-fifth {
    flex: 0 0 auto;
    width: calc(100% / 5 - 0.01px); }
  .has-gutter > .one-fifth {
    width: calc(100% / 5 - 1rem - 0.01px); }
  .has-gutter-l > .one-fifth {
    width: calc(100% / 5 - 2rem - 0.01px); }
  .has-gutter-xl > .one-fifth {
    width: calc(100% / 5 - 4rem - 0.01px); }
  .one-sixth {
    flex: 0 0 auto;
    width: calc(100% / 6 - 0.01px); }
  .has-gutter > .one-sixth {
    width: calc(100% / 6 - 1rem - 0.01px); }
  .has-gutter-l > .one-sixth {
    width: calc(100% / 6 - 2rem - 0.01px); }
  .has-gutter-xl > .one-sixth {
    width: calc(100% / 6 - 4rem - 0.01px); }
  .two-thirds {
    flex: 0 0 auto;
    width: calc(100% / 3 * 2 - 0.01px); }
  .has-gutter > .two-thirds {
    width: calc(100% / 3 * 2 - 1rem - 0.01px); }
  .has-gutter-l > .two-thirds {
    width: calc(100% / 3 * 2 - 2rem - 0.01px); }
  .has-gutter-xl > .two-thirds {
    width: calc(100% / 3 * 2 - 4rem - 0.01px); }
  .three-quarters {
    flex: 0 0 auto;
    width: calc(100% / 4 * 3 - 0.01px); }
  .has-gutter > .three-quarters {
    width: calc(100% / 4 * 3 - 1rem - 0.01px); }
  .has-gutter-l > .three-quarters {
    width: calc(100% / 4 * 3 - 2rem - 0.01px); }
  .has-gutter-xl > .three-quarters {
    width: calc(100% / 4 * 3 - 4rem - 0.01px); }
  .five-sixths {
    flex: 0 0 auto;
    width: calc(100% / 6 * 5 - 0.01px); }
  .has-gutter > .five-sixths {
    width: calc(100% / 6 * 5 - 1rem - 0.01px); }
  .has-gutter-l > .five-sixths {
    width: calc(100% / 6 * 5 - 2rem - 0.01px); }
  .has-gutter-xl > .five-sixths {
    width: calc(100% / 6 * 5 - 4rem - 0.01px); } }

/* Responsive Small Breakpoint */
@media (min-width: 576px) and (max-width: 767px) {
  [class*="-small-1"] > * {
    width: calc(100% / 1 - 0.01px); }
  [class*="-small-1"].has-gutter > * {
    width: calc(100% / 1 - 1rem - 0.01px); }
  [class*="-small-1"].has-gutter-l > * {
    width: calc(100% / 1 - 2rem - 0.01px); }
  [class*="-small-1"].has-gutter-xl > * {
    width: calc(100% / 1 - 4rem - 0.01px); }
  [class*="-small-2"] > * {
    width: calc(100% / 2 - 0.01px); }
  [class*="-small-2"].has-gutter > * {
    width: calc(100% / 2 - 1rem - 0.01px); }
  [class*="-small-2"].has-gutter-l > * {
    width: calc(100% / 2 - 2rem - 0.01px); }
  [class*="-small-2"].has-gutter-xl > * {
    width: calc(100% / 2 - 4rem - 0.01px); }
  [class*="-small-3"] > * {
    width: calc(100% / 3 - 0.01px); }
  [class*="-small-3"].has-gutter > * {
    width: calc(100% / 3 - 1rem - 0.01px); }
  [class*="-small-3"].has-gutter-l > * {
    width: calc(100% / 3 - 2rem - 0.01px); }
  [class*="-small-3"].has-gutter-xl > * {
    width: calc(100% / 3 - 4rem - 0.01px); }
  [class*="-small-4"] > * {
    width: calc(100% / 4 - 0.01px); }
  [class*="-small-4"].has-gutter > * {
    width: calc(100% / 4 - 1rem - 0.01px); }
  [class*="-small-4"].has-gutter-l > * {
    width: calc(100% / 4 - 2rem - 0.01px); }
  [class*="-small-4"].has-gutter-xl > * {
    width: calc(100% / 4 - 4rem - 0.01px); } }

/* ----------------------------- */
/* ==Media object                */
/* ----------------------------- */
/* recommended HTML : <div class="media"><img class="media-figure"><div class="media-content"></div></div> */
/* see http://codepen.io/raphaelgoetter/pen/KMWWwj */
@media (min-width: 480px) {
  .media {
    display: flex;
    align-items: flex-start; }
    .media-content {
      flex: 1 1 0%; }
    .media-figure--center {
      -ms-grid-row-align: center;
          align-self: center; }
    .media--reverse {
      flex-direction: row-reverse; } }

/* ----------------------------- */
/* ==skip links                  */
/* ----------------------------- */
/* see https://www.alsacreations.com/article/lire/572-Les-liens-d-evitement.html */
/* styling skip links */
.skip-links {
  position: absolute; }
  .skip-links a {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0.5em;
    background: black;
    color: white;
    text-decoration: none; }
    .skip-links a:focus {
      position: static;
      overflow: visible;
      clip: auto; }

/* ----------------------------- */
/* ==Tables                      */
/* ----------------------------- */
table,
.table {
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  vertical-align: top;
  margin-bottom: 2rem; }

.table {
  display: table;
  border: 1px solid #acb3c2;
  background: transparent; }
  .table--zebra tbody tr:nth-child(odd) {
    background: #e7e9ed; }
  .table caption {
    caption-side: bottom;
    padding: 1rem;
    color: #333;
    font-style: italic;
    text-align: right; }
  .table td,
  .table th {
    padding: 0.3rem 0.6rem;
    min-width: 2rem;
    vertical-align: top;
    border: 1px #acb3c2 dotted;
    text-align: left;
    cursor: default; }
  .table thead {
    color: #333030;
    background: transparent; }

.table--auto {
  table-layout: auto; }

/* ----------------------------- */
/* ==Forms                       */
/* ----------------------------- */
/* thanks to HTML5boilerplate and https://shoelace.style/ */
/* forms items */
form,
fieldset {
  border: none; }

fieldset {
  padding: 2rem; }
  fieldset legend {
    padding: 0 0.5rem;
    border: 0;
    white-space: normal; }

label {
  display: inline-block;
  cursor: pointer; }

[type="color"],
[type="date"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="submit"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  white-space: nowrap;
  font-family: inherit;
  font-size: inherit;
  border: 0;
  box-shadow: 0 0 0 1px #333 inset;
  color: #333030;
  vertical-align: middle;
  padding: 0.5rem 1rem;
  margin: 0;
  transition: 0.25s;
  transition-property: box-shadow, background-color, color, border;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

[type="submit"] {
  background-color: #333;
  color: #fff;
  cursor: pointer; }

input[readonly] {
  background-color: #e7e9ed; }

select {
  padding-right: 2rem;
  border-radius: 0;
  background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%209.96%2011.966%20L%203.523%205.589%20C%202.464%204.627%200.495%206.842%201.505%207.771%20L%201.505%207.771%20L%208.494%2014.763%20C%209.138%2015.35%2010.655%2015.369%2011.29%2014.763%20L%2011.29%2014.763%20L%2018.49%207.771%20C%2019.557%206.752%2017.364%204.68%2016.262%205.725%20L%2016.262%205.725%20L%209.96%2011.966%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E");
  background-position: right .6rem center;
  background-repeat: no-repeat;
  background-size: 1.2rem; }

/* hiding IE11 arrow */
select::-ms-expand {
  display: none; }

textarea {
  min-height: 5em;
  vertical-align: top;
  resize: vertical;
  white-space: normal; }

/* 'x' appears on right of search input when text is entered. This removes it */
[type="search"]::-webkit-search-decoration, [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-results-button, [type="search"]::-webkit-search-results-decoration {
  display: none; }

::-webkit-input-placeholder {
  color: #777; }

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #777; }

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #777; }

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #777; }

input::placeholder,
textarea::placeholder {
  color: #777; }

progress {
  width: 100%;
  vertical-align: middle; }

/* ----------------------------- */
/* ==Buttons                     */
/* ----------------------------- */
/* preferably use <button> for buttons !*/
/* use .btn-- or .button-- classes for variants */
.btn,
.button,
[type="button"],
button, .btn--primary,
.button--primary, .btn--success,
.button--success, .btn--info,
.button--info, .btn--warning,
.button--warning, .btn--danger,
.button--danger, .btn--inverse,
.button--inverse, .btn--ghost,
.button--ghost {
  display: inline-block;
  padding: 1rem 1.5rem;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: 0.25s;
  transition-property: box-shadow, background-color, color, border;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
  color: #333030;
  border: none;
  border-radius: 0;
  background-color: #e7e9ed;
  font-family: inherit;
  font-size: inherit;
  line-height: 1; }

.btn:focus,
.button:focus,
[type="button"]:focus,
button:focus {
  -webkit-tap-highlight-color: transparent; }

.btn--primary,
.button--primary {
  background-color: #0275D8;
  color: #fff;
  box-shadow: none; }
  .btn--primary:active, .btn--primary:focus, .btn--primary:hover,
  .button--primary:active,
  .button--primary:focus,
  .button--primary:hover {
    background-color: #025aa5; }

.btn--success,
.button--success {
  background-color: #5CB85C;
  color: #fff;
  box-shadow: none; }
  .btn--success:active, .btn--success:focus, .btn--success:hover,
  .button--success:active,
  .button--success:focus,
  .button--success:hover {
    background-color: #449d44; }

.btn--info,
.button--info {
  background-color: #5BC0DE;
  color: #161414;
  box-shadow: none; }
  .btn--info:active, .btn--info:focus, .btn--info:hover,
  .button--info:active,
  .button--info:focus,
  .button--info:hover {
    background-color: #31b0d5; }

.btn--warning,
.button--warning {
  background-color: #F0AD4E;
  color: #161414;
  box-shadow: none; }
  .btn--warning:active, .btn--warning:focus, .btn--warning:hover,
  .button--warning:active,
  .button--warning:focus,
  .button--warning:hover {
    background-color: #ec971f; }

.btn--danger,
.button--danger {
  background-color: #e94628;
  color: #fff;
  box-shadow: none; }
  .btn--danger:active, .btn--danger:focus, .btn--danger:hover,
  .button--danger:active,
  .button--danger:focus,
  .button--danger:hover {
    background-color: #c93115; }

.btn--inverse,
.button--inverse {
  background-color: #333;
  color: #fff;
  box-shadow: none; }
  .btn--inverse:active, .btn--inverse:focus, .btn--inverse:hover,
  .button--inverse:active,
  .button--inverse:focus,
  .button--inverse:hover {
    background-color: #1a1a1a; }

.btn--ghost,
.button--ghost {
  background-color: transparent;
  color: #fff;
  box-shadow: 0 0 0 1px #fff inset; }
  .btn--ghost:active, .btn--ghost:focus, .btn--ghost:hover,
  .button--ghost:active,
  .button--ghost:focus,
  .button--ghost:hover {
    background-color: rgba(0, 0, 0, 0); }

.btn--small,
.button--small {
  padding: 0.7rem 1rem;
  font-size: .8em; }

.btn--big,
.button--big {
  padding: 1.5rem 2rem;
  font-size: 1.4em; }

.btn--block,
.button--block {
  width: 100% !important;
  display: block; }

.btn--unstyled,
.button--unstyled {
  padding: 0;
  border: none;
  text-align: left;
  background: none;
  border-radius: 0;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .btn--unstyled:focus,
  .button--unstyled:focus {
    box-shadow: none;
    outline: none; }

.nav-button {
  padding: 0;
  background-color: transparent;
  outline: 0;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent; }
  .nav-button > * {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    height: 2.6rem;
    width: 2.6rem;
    padding: 0;
    background-color: transparent;
    background-image: linear-gradient(#333, #333);
    background-position: center;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-size: 100% 5px;
    transition: .25s;
    transition-property: background, -webkit-transform;
    transition-property: transform, background;
    transition-property: transform, background, -webkit-transform;
    will-change: transform, background; }
    .nav-button > *::before, .nav-button > *::after {
      content: "";
      height: 5px;
      background: #333;
      transition: .25s;
      transition-property: top, -webkit-transform;
      transition-property: transform, top;
      transition-property: transform, top, -webkit-transform;
      will-change: transform, top; }
  .nav-button:hover > * {
    background-color: transparent; }
  .nav-button:focus {
    outline: 0; }
  .nav-button.is-active > * {
    background-image: none;
    justify-content: center; }
    .nav-button.is-active > *::before {
      -webkit-transform: translateY(50%) rotate3d(0, 0, 1, 45deg);
              transform: translateY(50%) rotate3d(0, 0, 1, 45deg); }
    .nav-button.is-active > *::after {
      -webkit-transform: translateY(-50%) rotate3d(0, 0, 1, -45deg);
              transform: translateY(-50%) rotate3d(0, 0, 1, -45deg); }

/* ----------------------------- */
/* ==Checkbox, radio, switch     */
/* ----------------------------- */
/* use .checkbox class on input type=checkbox */
/* recommended HTML : <input type="checkbox" class="checkbox" id="c1"><label for="c1">click here</label> */
/* use .radio class on input type=radio */
/* recommended HTML : <input type="radio" class="radio" name="radio" id="r1"><label for="r1">Click here</label> */
/* use .switch class on input type=checkbox */
.checkbox {
  border-radius: 4px; }

.switch {
  border-radius: 3em; }

.radio {
  border-radius: 50%; }

.switch,
.checkbox,
.radio {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  vertical-align: text-bottom;
  outline: 0;
  cursor: pointer; }
  .switch ~ label,
  .checkbox ~ label,
  .radio ~ label {
    cursor: pointer; }
  .switch::-ms-check,
  .checkbox::-ms-check,
  .radio::-ms-check {
    display: none; }

.switch {
  width: 4rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 70%;
  box-shadow: inset -2rem 0 0 #333, inset 0 0 0 1px #333;
  transition: box-shadow .15s;
  background-color: #fff; }
  .switch::before, .switch::after {
    font-weight: bold;
    color: #fff; }
  .switch::before {
    content: "✕";
    float: right;
    margin-right: 0.66667rem; }
  .switch:checked {
    box-shadow: inset 2rem 0 0 #5CB85C, inset 0 0 0 1px #5CB85C; }
    .switch:checked::before {
      content: "✓";
      float: left;
      margin-left: 0.66667rem; }

.checkbox {
  width: 2rem;
  height: 2rem;
  box-shadow: inset 0 0 0 1px #333;
  background-color: #fff;
  transition: background-color .15s; }
  .checkbox:checked {
    background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220%22%20y%3D%220%22%20viewBox%3D%220%200%208%208%22%20enable-background%3D%22new%200%200%208%208%22%20xml%3Aspace%3D%22preserve%22%3E%20%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M6.4%2C1L5.7%2C1.7L2.9%2C4.5L2.1%2C3.7L1.4%2C3L0%2C4.4l0.7%2C0.7l1.5%2C1.5l0.7%2C0.7l0.7-0.7l3.5-3.5l0.7-0.7L6.4%2C1L6.4%2C1z%22%20%2F%3E%0A%3C%2Fsvg%3E");
    background-size: 60% 60%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #333; }

.radio {
  width: 2rem;
  height: 2rem;
  background-size: 0% 0%;
  transition: background-size .15s;
  box-shadow: inset 0 0 0 1px #333;
  background-color: #fff; }
  .radio:checked {
    background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20version%3D%221.1%22%20width%3D%22100%22%20height%3D%22100%22%0AviewBox%3D%220%200%2080%2080%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Ccircle%20cx%3D%2240%22%20cy%3D%2240%22%20r%3D%2224%22%20style%3D%22fill%3A%23333333%22/%3E%3C/svg%3E");
    background-size: 90% 90%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff; }

/* ----------------------------- */
/* ==Tabs                        */
/* ----------------------------- */
/* see example on https://knacss.com/styleguide.html#tabs */
/* NOTE : tabs need JavaScript to be activated */
.tabs-menu {
  border-bottom: 2px solid #e7e9ed; }
  .tabs-menu-link {
    display: block;
    margin-bottom: -2px;
    padding: 0.5rem 3rem;
    border-bottom: 4px solid transparent;
    color: #333030;
    background: transparent;
    text-decoration: none;
    border-radius: 0 0 0 0;
    transition: .25s;
    transition-property: color, border, background-color; }
    .tabs-menu-link.is-active {
      border-bottom-color: #333;
      color: #333;
      background: transparent;
      outline: 0; }
    .tabs-menu-link:focus {
      border-bottom-color: #333;
      color: #333;
      outline: 0; }
    @media (min-width: 576px) {
      .tabs-menu-link {
        display: inline-block; } }

.tabs-content-item {
  padding-top: 1rem; }
  .tabs-content-item[aria-hidden="true"] {
    visibility: hidden; }
  .tabs-content-item[aria-hidden="false"] {
    visibility: visible; }

/* ----------------------------- */
/* ==Arrows                      */
/* ----------------------------- */
/* see https://knacss.com/styleguide.html#arrows */
[class*="icon-arrow--"] {
  vertical-align: middle; }
  [class*="icon-arrow--"]::after {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    -webkit-mask-size: cover;
            mask-size: cover;
    background-color: #161414;
    line-height: 1; }

.icon-arrow--down::after {
  -webkit-mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%209.96%2011.966%20L%203.523%205.589%20C%202.464%204.627%200.495%206.842%201.505%207.771%20L%201.505%207.771%20L%208.494%2014.763%20C%209.138%2015.35%2010.655%2015.369%2011.29%2014.763%20L%2011.29%2014.763%20L%2018.49%207.771%20C%2019.557%206.752%2017.364%204.68%2016.262%205.725%20L%2016.262%205.725%20L%209.96%2011.966%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%209.96%2011.966%20L%203.523%205.589%20C%202.464%204.627%200.495%206.842%201.505%207.771%20L%201.505%207.771%20L%208.494%2014.763%20C%209.138%2015.35%2010.655%2015.369%2011.29%2014.763%20L%2011.29%2014.763%20L%2018.49%207.771%20C%2019.557%206.752%2017.364%204.68%2016.262%205.725%20L%2016.262%205.725%20L%209.96%2011.966%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E"); }

.icon-arrow--up::after {
  -webkit-mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%209.96%208.596%20L%203.523%2014.973%20C%202.464%2015.935%200.495%2013.72%201.505%2012.791%20L%201.505%2012.791%20L%208.494%205.799%20C%209.138%205.212%2010.655%205.193%2011.29%205.799%20L%2011.29%205.799%20L%2018.49%2012.791%20C%2019.557%2013.809%2017.364%2015.882%2016.262%2014.837%20L%2016.262%2014.837%20L%209.96%208.596%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%209.96%208.596%20L%203.523%2014.973%20C%202.464%2015.935%200.495%2013.72%201.505%2012.791%20L%201.505%2012.791%20L%208.494%205.799%20C%209.138%205.212%2010.655%205.193%2011.29%205.799%20L%2011.29%205.799%20L%2018.49%2012.791%20C%2019.557%2013.809%2017.364%2015.882%2016.262%2014.837%20L%2016.262%2014.837%20L%209.96%208.596%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E"); }

.icon-arrow--right::after {
  -webkit-mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%2011.685%2010.321%20L%205.308%2016.758%20C%204.346%2017.817%206.561%2019.786%207.49%2018.776%20L%207.49%2018.776%20L%2014.482%2011.787%20C%2015.069%2011.142%2015.088%209.626%2014.482%208.991%20L%2014.482%208.991%20L%207.49%201.791%20C%206.472%200.724%204.399%202.916%205.444%204.019%20L%205.444%204.019%20L%2011.685%2010.321%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%2011.685%2010.321%20L%205.308%2016.758%20C%204.346%2017.817%206.561%2019.786%207.49%2018.776%20L%207.49%2018.776%20L%2014.482%2011.787%20C%2015.069%2011.142%2015.088%209.626%2014.482%208.991%20L%2014.482%208.991%20L%207.49%201.791%20C%206.472%200.724%204.399%202.916%205.444%204.019%20L%205.444%204.019%20L%2011.685%2010.321%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E"); }

.icon-arrow--left::after {
  -webkit-mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%208.315%2010.321%20L%2014.692%2016.758%20C%2015.654%2017.817%2013.439%2019.786%2012.51%2018.776%20L%2012.51%2018.776%20L%205.518%2011.787%20C%204.931%2011.142%204.912%209.626%205.518%208.991%20L%205.518%208.991%20L%2012.51%201.791%20C%2013.528%200.724%2015.601%202.916%2014.556%204.019%20L%2014.556%204.019%20L%208.315%2010.321%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%208.315%2010.321%20L%2014.692%2016.758%20C%2015.654%2017.817%2013.439%2019.786%2012.51%2018.776%20L%2012.51%2018.776%20L%205.518%2011.787%20C%204.931%2011.142%204.912%209.626%205.518%208.991%20L%205.518%208.991%20L%2012.51%201.791%20C%2013.528%200.724%2015.601%202.916%2014.556%204.019%20L%2014.556%204.019%20L%208.315%2010.321%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E"); }

/* ----------------------------- */
/* ==Tags                      */
/* ----------------------------- */
/* use .tag-- classes for variants */
.tag, .tag--primary, .tag--success, .tag--info, .tag--warning, .tag--danger, .tag--inverse, .tag--ghost {
  display: inline-block;
  padding: 3px 0.5rem;
  vertical-align: baseline;
  white-space: nowrap;
  color: #333030;
  border-radius: 0;
  background-color: #e7e9ed;
  line-height: 1; }

.tag--primary {
  background-color: #0275D8;
  color: #fff;
  box-shadow: none; }

.tag--success {
  background-color: #5CB85C;
  color: #fff;
  box-shadow: none; }

.tag--info {
  background-color: #5BC0DE;
  color: #161414;
  box-shadow: none; }

.tag--warning {
  background-color: #F0AD4E;
  color: #161414;
  box-shadow: none; }

.tag--danger {
  background-color: #e94628;
  color: #fff;
  box-shadow: none; }

.tag--inverse {
  background-color: #333;
  color: #fff;
  box-shadow: none; }

.tag--ghost {
  background-color: transparent;
  color: #fff;
  box-shadow: 0 0 0 1px #fff inset; }

.tag--small {
  font-size: 1.2rem; }

.tag--big {
  font-size: 2rem; }

.tag--block {
  width: 100% !important;
  display: block; }

.tag.disabled, .disabled.tag--primary, .disabled.tag--success, .disabled.tag--info, .disabled.tag--warning, .disabled.tag--danger, .disabled.tag--inverse, .disabled.tag--ghost, .tag--disabled {
  opacity: 0.5;
  cursor: not-allowed; }

.tag:empty, .tag--primary:empty, .tag--success:empty, .tag--info:empty, .tag--warning:empty, .tag--danger:empty, .tag--inverse:empty, .tag--ghost:empty {
  display: none; }

/* ----------------------------- */
/* ==Badges                      */
/* ----------------------------- */
/* use .badge-- classes for variants */
.badge, .badge--primary, .badge--success, .badge--info, .badge--warning, .badge--danger, .badge--inverse, .badge--ghost {
  display: inline-block;
  padding: 0.5rem;
  border-radius: 50%;
  color: #333030;
  background-color: #e7e9ed;
  line-height: 1; }
  .badge::before, .badge--primary::before, .badge--success::before, .badge--info::before, .badge--warning::before, .badge--danger::before, .badge--inverse::before, .badge--ghost::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    padding-top: 100%; }

.badge--primary {
  background-color: #0275D8;
  color: #fff; }

.badge--success {
  background-color: #5CB85C;
  color: #fff; }

.badge--info {
  background-color: #5BC0DE;
  color: #161414; }

.badge--warning {
  background-color: #F0AD4E;
  color: #161414; }

.badge--danger {
  background-color: #e94628;
  color: #fff; }

.badge--inverse {
  background-color: #333;
  color: #fff; }

.badge--ghost {
  background-color: transparent;
  color: #fff; }

.badge--small {
  font-size: 1.2rem; }

.badge--big {
  font-size: 2rem; }

.badge.disabled, .disabled.badge--primary, .disabled.badge--success, .disabled.badge--info, .disabled.badge--warning, .disabled.badge--danger, .disabled.badge--inverse, .disabled.badge--ghost, .badge--disabled {
  opacity: 0.5;
  cursor: not-allowed; }

.badge:empty, .badge--primary:empty, .badge--success:empty, .badge--info:empty, .badge--warning:empty, .badge--danger:empty, .badge--inverse:empty, .badge--ghost:empty {
  display: none; }

/* ----------------------------- */
/* ==Alerts                      */
/* ----------------------------- */
/* use .alert-- classes for variants */
.alert, .alert--primary, .alert--success, .alert--info, .alert--warning, .alert--danger, .alert--inverse, .alert--ghost {
  padding: 1rem 1rem;
  margin-top: 0.75em;
  margin-bottom: 0;
  color: #333030;
  border-radius: 0;
  background-color: #e7e9ed; }
  .alert a, .alert--primary a, .alert--success a, .alert--info a, .alert--warning a, .alert--danger a, .alert--inverse a, .alert--ghost a {
    color: inherit;
    text-decoration: underline; }

.alert--primary {
  background-color: #0275D8;
  color: #fff;
  box-shadow: none; }

.alert--success {
  background-color: #5CB85C;
  color: #fff;
  box-shadow: none; }

.alert--info {
  background-color: #5BC0DE;
  color: #161414;
  box-shadow: none; }

.alert--warning {
  background-color: #F0AD4E;
  color: #161414;
  box-shadow: none; }

.alert--danger {
  background-color: #e94628;
  color: #fff;
  box-shadow: none; }

.alert--inverse {
  background-color: #333;
  color: #fff;
  box-shadow: none; }

.alert--ghost {
  background-color: transparent;
  color: #fff;
  box-shadow: 0 0 0 1px #fff inset; }

.alert--small {
  font-size: 1.2rem; }

.alert--big {
  font-size: 2rem; }

.alert--block {
  width: 100% !important;
  display: block; }

.alert.disabled, .disabled.alert--primary, .disabled.alert--success, .disabled.alert--info, .disabled.alert--warning, .disabled.alert--danger, .disabled.alert--inverse, .disabled.alert--ghost, .alert--disabled {
  opacity: 0.5;
  cursor: not-allowed; }

.alert:empty, .alert--primary:empty, .alert--success:empty, .alert--info:empty, .alert--warning:empty, .alert--danger:empty, .alert--inverse:empty, .alert--ghost:empty {
  display: none; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 1rem 1rem;
  display: flex;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 3rem;
  height: 2.4rem;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -0.2rem; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 3rem;
    height: 0.4rem;
    background-color: #000;
    border-radius: 0;
    position: absolute;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -1rem; }
  .hamburger-inner::after {
    bottom: -1rem; }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #161414;
  opacity: 0.95; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

html {
  display: flex;
  flex-direction: column; }

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }

main {
  flex: 1 1 auto;
  margin-top: 10rem; }
  @media (max-width: 576px) {
    main {
      margin-top: 6.4rem; } }

.is-fixed {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden; }

a {
  text-decoration: none;
  cursor: pointer;
  color: #161414;
  transition: color .3s ease-in-out; }
  a.underline {
    display: inline-block;
    position: relative; }
    a.underline:before {
      position: absolute;
      left: 0;
      right: 0;
      bottom: .1rem;
      height: .2rem;
      content: '';
      background-color: #e94628;
      transition: background-color .3s ease-in-out; }
    a.underline:hover {
      color: #e94628; }
      a.underline:hover:before {
        background-color: #161414; }

b,
strong {
  font-weight: 700; }

h1 {
  margin: 0 0 3rem -2rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 4.5rem;
  letter-spacing: .3rem;
  color: #fff; }
  @media (max-width: 992px) {
    h1 {
      font-size: 3.5rem; } }
  @media (max-width: 576px) {
    h1 {
      font-size: 2.5rem; } }
  h1 span {
    padding: 0 1rem;
    background-color: #333030; }

h2 {
  margin: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 3rem;
  letter-spacing: .1rem;
  line-height: 3.5rem;
  color: #161414;
  transition: color .3s ease-in-out; }
  @media (max-width: 992px) {
    h2 {
      font-size: 2.5rem; } }
  @media (max-width: 576px) {
    h2 {
      font-size: 2rem; } }

blockquote {
  padding-left: 8rem;
  font-style: italic;
  font-size: 2rem; }
  @media (max-width: 992px) {
    blockquote {
      font-size: 1.8rem; } }
  @media (max-width: 768px) {
    blockquote {
      padding-left: 4rem; } }
  @media (max-width: 480px) {
    blockquote {
      padding-left: 2rem; } }
  blockquote:before {
    left: -2rem;
    top: -2rem;
    font-size: 19rem;
    color: #eaeaea; }
    @media (max-width: 768px) {
      blockquote:before {
        font-size: 12rem;
        height: 5rem; } }
    @media (max-width: 480px) {
      blockquote:before {
        top: -1rem;
        font-size: 8rem;
        height: 3rem; } }

ul li {
  margin-bottom: 1rem; }
  ul li:last-of-type {
    margin-bottom: 0; }

@-webkit-keyframes float {
  0% {
    bottom: 1rem; }
  50% {
    bottom: .6rem; }
  100% {
    bottom: 1rem; } }

@keyframes float {
  0% {
    bottom: 1rem; }
  50% {
    bottom: .6rem; }
  100% {
    bottom: 1rem; } }

@media (max-width: 768px) {
  @-webkit-keyframes float {
    0% {
      bottom: -.4rem; }
    50% {
      bottom: -.8rem; }
    100% {
      bottom: -.4rem; } }
  @keyframes float {
    0% {
      bottom: -.4rem; }
    50% {
      bottom: -.8rem; }
    100% {
      bottom: -.4rem; } } }

svg, path {
  transition: fill .3s ease-in-out; }

.fill-rouge {
  fill: #e94628; }

.fill-noir {
  fill: #161414; }

.container-tiny, .container-small, .container, .container-large {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem; }

.container-tiny {
  max-width: 70rem; }

.container-small {
  max-width: 90rem; }

.container {
  max-width: 120rem; }

.container-large {
  max-width: 140rem; }

.p-section {
  padding-top: 10rem;
  padding-bottom: 10rem; }
  @media (max-width: 1200px) {
    .p-section {
      padding-top: 7rem;
      padding-bottom: 7rem; } }
  @media (max-width: 768px) {
    .p-section {
      padding-top: 4rem;
      padding-bottom: 4rem; } }
  @media (max-width: 480px) {
    .p-section {
      padding-top: 3rem;
      padding-bottom: 3rem; } }

.noir {
  color: #ebe5e5;
  background-color: #161414; }
  .noir a {
    color: #fff; }
    .noir a:hover {
      color: #e94628; }
      .noir a:hover:before {
        background-color: #fff; }

.clair {
  background-color: rgba(235, 229, 229, 0.2); }

.align-items-center {
  align-items: center; }

.justify-content-center {
  justify-content: center; }

.pagination {
  width: 100%;
  padding: 4rem 0 0;
  text-align: center; }
  @media (max-width: 768px) {
    .pagination {
      padding-top: 3rem; } }
  @media (max-width: 480px) {
    .pagination {
      padding-top: 2rem; } }
  .pagination span, .pagination a {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    color: #fff; }
    @media (max-width: 480px) {
      .pagination span, .pagination a {
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1.3rem; } }
  .pagination span {
    margin: 0 .25rem; }
  .pagination .current {
    padding: 1rem;
    background-color: #e94628; }
    @media (max-width: 480px) {
      .pagination .current {
        padding: .5rem; } }
  .pagination a {
    padding: 1rem;
    text-decoration: none;
    background-color: #161414;
    transition: background-color .3s ease-in-out; }
    @media (max-width: 480px) {
      .pagination a {
        padding: .5rem; } }
    .pagination a:hover {
      background-color: #636060; }

.mentions-legales h2 {
  margin: 2rem 0 1rem; }

.plan-du-site li {
  list-style-type: none; }

.plan-du-site a:hover {
  color: #e94628; }

.plan-du-site .page--sitemap__second, .plan-du-site .page--sitemap__third {
  margin-top: 1rem; }

.plan-du-site .page--sitemap__first {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2.2rem;
  letter-spacing: .1rem; }
  @media (max-width: 768px) {
    .plan-du-site .page--sitemap__first {
      padding-left: 0;
      font-size: 1.6rem; } }

.plan-du-site .page--sitemap__second {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 2rem; }
  @media (max-width: 768px) {
    .plan-du-site .page--sitemap__second {
      padding-left: 1rem;
      font-size: 1.4rem; } }

.plan-du-site .page--sitemap__third {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.6rem;
  text-transform: none;
  letter-spacing: 0; }
  @media (max-width: 768px) {
    .plan-du-site .page--sitemap__third {
      padding-left: 2rem;
      font-size: 1.2rem; } }
  .plan-du-site .page--sitemap__third li:not(:last-of-type) {
    padding-right: 1rem; }
    @media (max-width: 768px) {
      .plan-du-site .page--sitemap__third li:not(:last-of-type) {
        padding-right: .5rem; } }
    .plan-du-site .page--sitemap__third li:not(:last-of-type) a {
      padding-right: .5rem; }
      @media (max-width: 768px) {
        .plan-du-site .page--sitemap__third li:not(:last-of-type) a {
          padding-right: .25rem; } }
    .plan-du-site .page--sitemap__third li:not(:last-of-type):after {
      content: '—'; }

.erreur-404 {
  display: flex;
  align-items: center;
  height: 100%;
  min-height: calc(100vh - 39.5rem);
  color: #ebe5e5;
  background-color: #e94628; }
  @media (max-width: 992px) {
    .erreur-404 {
      min-height: calc(100vh - 38.5rem); } }
  .erreur-404 h1 {
    margin: 0 0 3rem 0;
    font-size: 9rem; }
    @media (max-width: 992px) {
      .erreur-404 h1 {
        font-size: 5rem; } }
    @media (max-width: 576px) {
      .erreur-404 h1 {
        margin-bottom: 2rem;
        font-size: 3rem; } }
  .erreur-404 a {
    color: #fff; }
    .erreur-404 a:hover {
      color: #161414; }

header {
  position: fixed;
  width: 100%;
  z-index: 5; }
  header.is-mobile-nav-open .links-wrapper {
    pointer-events: auto;
    opacity: 1; }

nav {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 10rem;
  padding: 1rem;
  background-color: #fff; }
  @media (max-width: 576px) {
    nav {
      height: 6.4rem; } }

@media (min-width: 577px) {
  .header__logo {
    padding: 1rem; } }

.header__logo .logo-bernard-bavaud {
  width: 16rem;
  height: 5.7rem;
  vertical-align: middle; }
  @media (max-width: 576px) {
    .header__logo .logo-bernard-bavaud {
      width: 12rem;
      height: 4.2rem; } }

.header__logo:hover .logo-bernard-bavaud {
  fill: #333030; }

.links-wrapper {
  align-items: center; }
  @media (max-width: 992px) {
    .links-wrapper {
      flex-direction: column;
      justify-content: center;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      height: calc(100vh - 10rem);
      padding: 1rem;
      text-align: center;
      pointer-events: none;
      background-color: rgba(22, 20, 20, 0.9);
      opacity: 0;
      transition: opacity .3s ease-in-out; } }
  @media (max-width: 576px) {
    .links-wrapper {
      height: calc(100vh - 6.4rem); } }

.categories-drop-down {
  position: relative; }
  .categories-drop-down .nav-link {
    z-index: 2; }
  @media (min-width: 993px) {
    .categories-drop-down:hover .nav-categories-wrapper {
      top: 6rem;
      pointer-events: auto;
      opacity: 1; } }
  @media (min-width: 993px) and (max-width: 1200px) {
    .categories-drop-down:hover .nav-categories-wrapper {
      top: 4rem; } }

.nav-categories-wrapper {
  flex-direction: column; }
  @media (min-width: 993px) {
    .nav-categories-wrapper {
      align-items: flex-start;
      position: absolute;
      top: 7rem;
      left: 1rem;
      max-width: 30rem;
      width: 300%;
      pointer-events: none;
      opacity: 0;
      z-index: 1;
      transition: all .3s ease-in-out; } }
  @media (max-width: 1200px) {
    .nav-categories-wrapper {
      top: 5rem; } }
  @media (max-width: 992px) {
    .nav-categories-wrapper {
      align-items: center; } }

.nav-link.nav-sub-link {
  padding: 1rem;
  font-weight: 700;
  letter-spacing: .2rem;
  text-transform: uppercase; }
  @media (min-width: 993px) {
    .nav-link.nav-sub-link span {
      padding: 0 .3rem;
      color: #ebe5e5;
      background-color: #333030;
      transition: all .3s ease-in-out; }
    .nav-link.nav-sub-link:hover span {
      color: #fff;
      background-color: #e94628; }
    .nav-link.nav-sub-link.underline:before {
      content: none; } }
  @media (max-width: 1200px) {
    .nav-link.nav-sub-link {
      padding: 1rem 0; } }
  @media (max-width: 992px) {
    .nav-link.nav-sub-link {
      padding: 1rem; } }
  @media (max-width: 480px) {
    .nav-link.nav-sub-link {
      padding: .5rem 1rem; } }

.nav-link {
  margin-top: .4rem;
  padding: 2rem;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .2rem;
  font-weight: 700; }
  @media (max-width: 1200px) {
    .nav-link {
      padding: 1rem;
      font-size: 1.5rem; } }
  @media (max-width: 992px) {
    .nav-link {
      color: #fff; }
      .nav-link.is-active {
        color: #e94628; } }
  @media (max-width: 480px) {
    .nav-link {
      margin: 0;
      padding: .5rem 1rem;
      font-size: 1.4rem; } }
  .nav-link.underline:before {
    left: 2rem;
    right: 2rem;
    bottom: 1.5rem;
    height: .4rem;
    opacity: 0;
    transition: all .3s ease-in-out; }
    @media (max-width: 1200px) {
      .nav-link.underline:before {
        left: 1rem;
        right: 1rem;
        bottom: .5rem; } }
    @media (max-width: 576px) {
      .nav-link.underline:before {
        height: .2rem; } }
    @media (max-width: 480px) {
      .nav-link.underline:before {
        bottom: -.2rem; } }
  .nav-link:hover.underline:before, .nav-link.is-active.underline:before {
    bottom: 2rem;
    opacity: 1; }
    @media (max-width: 1200px) {
      .nav-link:hover.underline:before, .nav-link.is-active.underline:before {
        bottom: 1rem; } }
    @media (max-width: 992px) {
      .nav-link:hover.underline:before, .nav-link.is-active.underline:before {
        background-color: #fff; } }
    @media (max-width: 480px) {
      .nav-link:hover.underline:before, .nav-link.is-active.underline:before {
        bottom: .4rem; } }

.hamburger {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  outline: none; }
  @media (min-width: 993px) {
    .hamburger {
      display: none; } }
  .hamburger .hamburger-label {
    margin-right: 1rem;
    text-transform: uppercase;
    font-weight: 400;
    color: #161414; }

body:not(.accueil) nav {
  background-color: #161414; }
  body:not(.accueil) nav .header__logo .logo-bernard-bavaud .fill-noir {
    fill: #fff; }
  body:not(.accueil) nav .header__logo:hover .logo-bernard-bavaud .fill-noir {
    fill: #ebe5e5; }
  body:not(.accueil) nav .nav-link {
    color: #fff; }
    body:not(.accueil) nav .nav-link.is-active {
      color: #fff; }
      body:not(.accueil) nav .nav-link.is-active.underline:before {
        background-color: #e94628; }
    body:not(.accueil) nav .nav-link:hover {
      color: #e94628; }
      body:not(.accueil) nav .nav-link:hover.underline:before {
        background-color: #fff; }
  body:not(.accueil) nav .hamburger .hamburger-label {
    color: #fff; }
  body:not(.accueil) nav .hamburger .hamburger-box .hamburger-inner, body:not(.accueil) nav .hamburger .hamburger-box .hamburger-inner:before, body:not(.accueil) nav .hamburger .hamburger-box .hamburger-inner:after {
    background-color: #fff; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/poppins-regular.woff2") format("woff2"), url("../fonts/poppins/poppins-regular.woff") format("woff"), url("../fonts/poppins/poppins-regular.ttf") format("truetype"), url("../font/poppins/poppins-regular.svg#PoppinsRegular") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/poppins-italic.woff2") format("woff2"), url("../fonts/poppins/poppins-italic.woff") format("woff"), url("../fonts/poppins/poppins-italic.ttf") format("truetype"), url("../font/poppins/poppins-italic.svg#PoppinsItalic") format("svg");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/poppins-semibold.woff2") format("woff2"), url("../fonts/poppins/poppins-semibold.woff") format("woff"), url("../fonts/poppins/poppins-semibold.ttf") format("truetype"), url("../font/poppins/poppins-semibold.svg#PoppinsSemiBold") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/poppins/poppins-black.woff2") format("woff2"), url("../fonts/poppins/poppins-black.woff") format("woff"), url("../fonts/poppins/poppins-black.ttf") format("truetype"), url("../font/poppins/poppins-black.svg#PoppinsBlack") format("svg");
  font-weight: 900;
  font-style: normal; }

input[type=number] {
  -moz-appearance: textfield; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 3rem white inset;
  color: #161414; }

::-ms-clear {
  display: none; }

::-ms-reveal {
  display: none; }

input[type=text], textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgab(#161414, 0.7); }

::-moz-placeholder {
  /* Firefox 19+ */
  color: rgab(#161414, 0.7); }

:-ms-input-placeholder {
  /* IE 10+ */
  color: rgab(#161414, 0.7); }

:-moz-placeholder {
  /* Firefox 18- */
  color: rgab(#161414, 0.7); }

textarea,
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="file"],
input[type="date"],
input[type="email"],
input[type="password"] {
  display: inline-block;
  width: 100%;
  padding: 1rem;
  font-size: 1.4rem;
  color: #161414;
  line-height: inherit;
  vertical-align: middle;
  box-shadow: 0 0 0 0.1rem rgba(22, 20, 20, 0.3) inset;
  border-radius: 0;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all .3s ease-in-out; }
  textarea:hover, textarea:focus, textarea:active,
  input[type="text"]:hover,
  input[type="text"]:focus,
  input[type="text"]:active,
  input[type="tel"]:hover,
  input[type="tel"]:focus,
  input[type="tel"]:active,
  input[type="url"]:hover,
  input[type="url"]:focus,
  input[type="url"]:active,
  input[type="file"]:hover,
  input[type="file"]:focus,
  input[type="file"]:active,
  input[type="date"]:hover,
  input[type="date"]:focus,
  input[type="date"]:active,
  input[type="email"]:hover,
  input[type="email"]:focus,
  input[type="email"]:active,
  input[type="password"]:hover,
  input[type="password"]:focus,
  input[type="password"]:active {
    outline: none;
    box-shadow: 0 0 0 0.1rem rgba(22, 20, 20, 0.8) inset;
    background-color: rgba(235, 229, 229, 0.3); }
  textarea.error,
  input[type="text"].error,
  input[type="tel"].error,
  input[type="url"].error,
  input[type="file"].error,
  input[type="date"].error,
  input[type="email"].error,
  input[type="password"].error {
    box-shadow: 0 0 0 0.1rem #e94628 inset; }

label {
  margin-bottom: .7rem;
  text-transform: uppercase;
  color: #161414;
  font-weight: 300;
  letter-spacing: .1rem;
  font-size: 1.4rem; }
  label.error {
    margin-top: .5rem;
    color: #e94628;
    font-weight: 400;
    font-size: 1.3rem; }

textarea {
  height: 15rem;
  overflow: hidden;
  resize: none; }

.form-group {
  margin-top: 2rem; }

.btn--rouge {
  margin-top: 2rem;
  padding: 1rem;
  border: none;
  outline: none;
  font-size: 1.8rem;
  letter-spacing: .1rem;
  text-transform: uppercase;
  box-shadow: none;
  background-color: #e94628;
  transition: background-color .3s ease-in-out; }
  .btn--rouge svg {
    margin-right: .3rem;
    vertical-align: baseline;
    fill: #fff; }
  .btn--rouge:hover {
    background-color: #333030; }

@media (max-width: 768px) {
  .grid-2-small-1 .form-group:first-of-type {
    margin-top: 0; } }

@media (max-width: 480px) {
  #rc-imageselect, .g-recaptcha {
    -webkit-transform: scale(0.8) !important;
            transform: scale(0.8) !important;
    -webkit-transform-origin: 0 50% !important;
            transform-origin: 0 50% !important; } }

footer {
  position: relative;
  overflow: hidden;
  color: #ebe5e5;
  background-color: #333030; }
  footer .logo-bernard-bavaud-texte {
    position: absolute;
    left: -10rem;
    top: 2rem;
    width: 140rem;
    fill: rgba(99, 96, 96, 0.1); }
  footer .container {
    justify-content: center;
    position: relative;
    text-align: center;
    z-index: 1; }
  footer .footer-title {
    margin: 0 0 5rem;
    text-transform: uppercase;
    letter-spacing: .2rem;
    font-size: 2.5rem;
    font-weight: 900; }
    @media (max-width: 992px) {
      footer .footer-title {
        font-size: 2rem; } }
    @media (max-width: 576px) {
      footer .footer-title {
        margin-bottom: 2rem;
        font-size: 1.5rem; } }
  footer a {
    color: #fff; }
    footer a:hover {
      color: #e94628; }
  footer .footer-link {
    margin: 0 1.5rem;
    padding: 1rem;
    font-weight: 300;
    letter-spacing: .1rem;
    text-transform: uppercase; }
    @media (max-width: 992px) {
      footer .footer-link {
        margin: 0 1rem;
        font-size: 1.4rem; } }
    @media (max-width: 576px) {
      footer .footer-link {
        font-size: 1.2rem; } }
    footer .footer-link.underline:before {
      left: 1rem;
      right: 1rem;
      bottom: .3rem;
      height: .4rem;
      opacity: 0;
      transition: all .3s ease-in-out; }
    footer .footer-link:hover.underline:before, footer .footer-link.is-active.underline:before {
      bottom: .7rem;
      opacity: 1; }

.footer__content {
  padding-top: 8rem;
  padding-bottom: 6rem; }
  @media (max-width: 576px) {
    .footer__content {
      padding-top: 5rem;
      padding-bottom: 3rem; } }

.footer__bottom p, .footer__bottom > a {
  margin: 0;
  padding: .3rem 1rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  opacity: .7; }
  @media (max-width: 576px) {
    .footer__bottom p, .footer__bottom > a {
      font-size: 1rem; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%; } }

.b-lazy {
  max-width: 100%;
  opacity: 0;
  transition: opacity .2s ease; }

.b-lazy.b-loaded {
  opacity: 1; }

.obf {
  cursor: pointer; }

.accueil h2 {
  margin-left: -2rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 4.5rem;
  letter-spacing: .3rem;
  color: #fff; }
  @media (max-width: 992px) {
    .accueil h2 {
      font-size: 3.5rem; } }
  @media (max-width: 576px) {
    .accueil h2 {
      font-size: 2.5rem; } }
  .accueil h2 span {
    padding: 0 1rem;
    background-color: #333030; }

.intro-accueil-wrapper {
  position: relative; }

.intro-texte-wrapper {
  margin-bottom: 10rem; }
  @media (max-width: 1200px) {
    .intro-texte-wrapper {
      margin-bottom: 7rem; } }
  @media (max-width: 768px) {
    .intro-texte-wrapper {
      margin-bottom: 4rem; } }
  @media (max-width: 480px) {
    .intro-texte-wrapper {
      margin-bottom: 3rem; } }
  .intro-texte-wrapper p:first-of-type {
    font-size: 1.2rem;
    text-align: right; }
  .intro-texte-wrapper p:last-of-type {
    margin: 5rem 0 0; }
    @media (max-width: 768px) {
      .intro-texte-wrapper p:last-of-type {
        margin-top: 3rem; } }

.intro-titre-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 2rem;
  text-align: center;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  z-index: 1; }
  .intro-titre-wrapper h1 {
    margin: 0; }
  .intro-titre-wrapper .logo-bernard-bavaud {
    position: relative;
    width: 50rem;
    height: 17.7rem;
    margin: 0 auto;
    z-index: 2; }
    @media (max-width: 992px) {
      .intro-titre-wrapper .logo-bernard-bavaud {
        width: 25rem;
        height: 8.8rem; } }
    .intro-titre-wrapper .logo-bernard-bavaud .fill-noir {
      fill: #fff; }
  .intro-titre-wrapper h2 {
    margin: -3rem -7rem 0 0;
    line-height: 3.5rem; }
    @media (max-width: 992px) {
      .intro-titre-wrapper h2 {
        margin: -2rem -6rem 0 0;
        line-height: 2.5rem; } }
    @media (max-width: 480px) {
      .intro-titre-wrapper h2 {
        margin: -2rem 0 0; } }
    .intro-titre-wrapper h2 span {
      font-size: 3rem;
      color: #e94628;
      background-color: #161414; }
      @media (max-width: 992px) {
        .intro-titre-wrapper h2 span {
          font-size: 2rem; } }

.arrow-down {
  position: absolute;
  left: 50%;
  bottom: 1rem;
  padding: 1rem;
  z-index: 2;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-animation: float 1.5s infinite ease-in-out;
          animation: float 1.5s infinite ease-in-out; }
  .arrow-down svg {
    fill: #fff; }
    @media (max-width: 768px) {
      .arrow-down svg {
        width: 2rem;
        height: 2rem; } }

.intro-accueil {
  position: relative; }
  @media (min-width: 2021px) {
    .intro-accueil {
      height: calc(100vh - 10rem); } }
  @media (max-width: 2020px) {
    .intro-accueil {
      height: 0;
      padding: 0 0 46.68%; } }
  @media (max-width: 768px) {
    .intro-accueil {
      padding: 0 0 70%; } }
  @media (max-width: 480px) {
    .intro-accueil {
      padding: 0 0 100%; } }
  .intro-accueil .buste-accueil {
    position: relative;
    width: 33.33%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
    @media (max-width: 2020px) {
      .intro-accueil .buste-accueil {
        position: absolute;
        top: 0;
        left: 0; }
        .intro-accueil .buste-accueil:nth-of-type(2) {
          left: 33.33%; }
        .intro-accueil .buste-accueil:nth-of-type(3) {
          left: 66.66%; } }
    @media (max-width: 768px) {
      .intro-accueil .buste-accueil {
        width: 50%; }
        .intro-accueil .buste-accueil:nth-of-type(2) {
          left: 50%; }
        .intro-accueil .buste-accueil:nth-of-type(3) {
          display: none; } }
    @media (max-width: 480px) {
      .intro-accueil .buste-accueil {
        width: 100%; }
        .intro-accueil .buste-accueil:nth-of-type(2) {
          display: none; } }
    .intro-accueil .buste-accueil > span {
      position: absolute;
      left: 2rem;
      right: 2rem;
      bottom: -1rem;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: .1rem;
      opacity: 0;
      transition: all .3s ease-in-out; }
      @media (max-width: 768px) {
        .intro-accueil .buste-accueil > span {
          left: 1rem;
          right: 1rem;
          bottom: -1.5rem;
          font-size: 1.1rem; } }
      .intro-accueil .buste-accueil > span span {
        padding: 0 .5rem;
        background-color: #ebe5e5; }
    .intro-accueil .buste-accueil:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      background-color: rgba(22, 20, 20, 0.7);
      transition: background-color .3s ease-in-out; }
    .intro-accueil .buste-accueil:hover span {
      bottom: 1.5rem;
      opacity: 1; }
      @media (max-width: 768px) {
        .intro-accueil .buste-accueil:hover span {
          bottom: 1rem; } }
    .intro-accueil .buste-accueil:hover:before {
      background-color: transparent; }

.categories-accueil .flex-container, .categories-accueil .flex-container--row,
.categories-accueil .flex-row, .categories-accueil .flex-container--column,
.categories-accueil .flex-column, .categories-accueil .flex-container--row-reverse,
.categories-accueil .flex-row-reverse, .categories-accueil .flex-container--column-reverse,
.categories-accueil .flex-column-reverse {
  margin: 4rem -4rem -4rem; }
  @media (max-width: 2020px) {
    .categories-accueil .flex-container, .categories-accueil .flex-container--row,
    .categories-accueil .flex-row, .categories-accueil .flex-container--column,
    .categories-accueil .flex-column, .categories-accueil .flex-container--row-reverse,
    .categories-accueil .flex-row-reverse, .categories-accueil .flex-container--column-reverse,
    .categories-accueil .flex-column-reverse {
      margin: 4rem -2rem -4rem; } }
  @media (max-width: 1200px) {
    .categories-accueil .flex-container, .categories-accueil .flex-container--row,
    .categories-accueil .flex-row, .categories-accueil .flex-container--column,
    .categories-accueil .flex-column, .categories-accueil .flex-container--row-reverse,
    .categories-accueil .flex-row-reverse, .categories-accueil .flex-container--column-reverse,
    .categories-accueil .flex-column-reverse {
      margin: 4rem 1rem 1rem; } }

.categorie-accueil, .all-categories-accueil {
  width: calc(33.33% - 8rem);
  height: 33.28rem;
  margin: 4rem;
  padding: .7rem 1rem;
  border: 0.2rem solid #e94628; }
  @media (max-width: 1200px) {
    .categorie-accueil, .all-categories-accueil {
      width: calc(33.33% - 5rem);
      margin: 2.5rem; } }
  @media (max-width: 992px) {
    .categorie-accueil, .all-categories-accueil {
      width: calc(50% - 8rem);
      margin: 4rem; } }
  @media (max-width: 768px) {
    .categorie-accueil, .all-categories-accueil {
      width: calc(50% - 4rem);
      margin: 2rem; } }
  @media (max-width: 576px) {
    .categorie-accueil, .all-categories-accueil {
      width: 100%;
      height: 40rem;
      margin: 0 0 4rem; }
      .categorie-accueil.categorie-accueil-nusdepoche, .categorie-accueil.categorie-accueil-academique, .all-categories-accueil.categorie-accueil-nusdepoche, .all-categories-accueil.categorie-accueil-academique {
        z-index: 2; }
      .categorie-accueil.all-categories-accueil, .all-categories-accueil.all-categories-accueil {
        margin-bottom: 0; } }
  @media (max-width: 480px) {
    .categorie-accueil, .all-categories-accueil {
      height: 30rem;
      margin: 0 0 3rem; } }
  .categorie-accueil h3, .all-categories-accueil h3 {
    margin: 0;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 3rem;
    letter-spacing: .1rem;
    line-height: 3.5rem;
    color: #161414;
    transition: color .3s ease-in-out; }
    @media (max-width: 992px) {
      .categorie-accueil h3, .all-categories-accueil h3 {
        font-size: 2.5rem; } }
    @media (max-width: 576px) {
      .categorie-accueil h3, .all-categories-accueil h3 {
        font-size: 2rem; } }

.categorie-accueil {
  position: relative;
  transition: border-color .3s ease-in-out; }
  .categorie-accueil:hover {
    border-color: transparent; }
    .categorie-accueil:hover:before {
      opacity: 1; }
    .categorie-accueil:hover h3 {
      color: #fff; }
  .categorie-accueil:before {
    position: absolute;
    top: -.2rem;
    left: -.2rem;
    right: -.2rem;
    bottom: -.2rem;
    content: '';
    mix-blend-mode: multiply;
    opacity: 0;
    background-color: rgba(233, 70, 40, 0.8);
    transition: opacity .3s ease-in-out;
    z-index: 2; }
  .categorie-accueil .categorie-accueil-link {
    position: absolute;
    top: -4rem;
    left: -4rem;
    right: -4rem;
    bottom: -4rem;
    z-index: 4; }
    @media (max-width: 2020px) {
      .categorie-accueil .categorie-accueil-link {
        top: -2rem;
        left: -2rem;
        right: -2rem;
        bottom: -2rem; } }
  .categorie-accueil h3 {
    position: absolute;
    top: .7rem;
    left: 1rem;
    right: 1rem;
    z-index: 3; }
  .categorie-accueil .categorie-accueil-image {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    z-index: 1; }
    @media (max-width: 480px) {
      .categorie-accueil .categorie-accueil-image {
        max-width: 24rem; } }
  .categorie-accueil.categorie-accueil-bustesportraits img {
    left: calc(50% - 1rem);
    width: 27rem; }
  .categorie-accueil.categorie-accueil-sculptures img {
    top: calc(50% + 3rem); }
  .categorie-accueil.categorie-accueil-gravures img {
    top: calc(50% - 1rem); }
  .categorie-accueil.categorie-accueil-academique img {
    width: 26rem; }

.all-categories-accueil {
  background-color: #e94628;
  transition: background-color .3s ease-in-out; }
  .all-categories-accueil h3 {
    display: inline; }
  .all-categories-accueil svg {
    width: 2rem;
    height: 2rem;
    margin-left: .5rem;
    fill: #161414;
    transition: fill .3s ease-in-out -.3s; }
  .all-categories-accueil:hover {
    background-color: transparent; }
    .all-categories-accueil:hover h3 {
      color: #e94628; }
    .all-categories-accueil:hover svg {
      fill: #e94628; }

.actualite-accueil {
  position: relative;
  margin: 0 2rem; }
  .actualite-accueil .actualite-texte-wrapper {
    flex-direction: column;
    align-items: flex-end;
    width: calc(50% - 4rem); }
    @media (max-width: 1200px) {
      .actualite-accueil .actualite-texte-wrapper {
        width: 100%; } }
  .actualite-accueil .actualite-texte {
    position: relative; }
    @media (min-width: 1201px) {
      .actualite-accueil .actualite-texte {
        flex-grow: 1;
        max-width: 56rem;
        height: 100%;
        margin: 0 0 0 auto;
        padding: 0 4rem 0 2rem; } }
    @media (max-width: 1200px) {
      .actualite-accueil .actualite-texte {
        padding: 2rem 2rem 3rem; } }
    .actualite-accueil .actualite-texte h2 {
      margin: -1rem 0 0 -2rem;
      color: #e94628; }
      @media (min-width: 1201px) {
        .actualite-accueil .actualite-texte h2 {
          width: 150%; } }
      @media (max-width: 1200px) {
        .actualite-accueil .actualite-texte h2 {
          margin: -3rem 0 0 -2rem; } }
    .actualite-accueil .actualite-texte h3 {
      position: relative;
      width: 150%;
      margin: 10rem 0 3rem;
      font-weight: 300;
      font-size: 5rem;
      color: #fff; }
      @media (min-width: 1201px) {
        .actualite-accueil .actualite-texte h3:before {
          position: absolute;
          left: -7rem;
          top: 50%;
          content: '—';
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%); } }
      @media (max-width: 1200px) {
        .actualite-accueil .actualite-texte h3 {
          width: 100%;
          margin-top: 5rem; } }
      @media (max-width: 992px) {
        .actualite-accueil .actualite-texte h3 {
          margin-bottom: 1.5rem;
          font-size: 4rem; } }
      @media (max-width: 768px) {
        .actualite-accueil .actualite-texte h3 {
          margin-top: 4rem; } }
      @media (max-width: 576px) {
        .actualite-accueil .actualite-texte h3 {
          font-size: 3rem; } }
      @media (max-width: 480px) {
        .actualite-accueil .actualite-texte h3 {
          margin-top: 3rem; } }
  .actualite-accueil .accueil-actualite-date {
    margin: 0 0 4rem; }
    @media (max-width: 992px) {
      .actualite-accueil .accueil-actualite-date {
        margin-bottom: 2rem;
        font-size: 1.2rem;
        font-weight: 700; } }
    .actualite-accueil .accueil-actualite-date span {
      padding: .2rem .5rem 0;
      color: #161414;
      background-color: #fff; }
  .actualite-accueil .lien-actualite {
    position: relative;
    display: block;
    margin: 4rem 0 6rem;
    text-transform: uppercase;
    font-weight: 700; }
    @media (max-width: 1200px) {
      .actualite-accueil .lien-actualite {
        margin: 4rem 0 0; } }
    @media (max-width: 480px) {
      .actualite-accueil .lien-actualite {
        margin-top: 2rem; } }
  .actualite-accueil .lien-actualites-all {
    position: absolute;
    right: 4rem;
    bottom: -1rem; }
    @media (max-width: 480px) {
      .actualite-accueil .lien-actualites-all {
        left: 0;
        right: 0; } }
    .actualite-accueil .lien-actualites-all span {
      padding: .3rem .6rem;
      text-transform: uppercase;
      letter-spacing: .2rem;
      background-color: #e94628;
      transition: background-color .3s ease-in-out; }
    .actualite-accueil .lien-actualites-all svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: .3rem;
      vertical-align: baseline;
      fill: #fff; }
    .actualite-accueil .lien-actualites-all:hover {
      color: #fff; }
      .actualite-accueil .lien-actualites-all:hover span {
        background-color: #333030; }
  .actualite-accueil .actualite-image-wrapper {
    width: calc(50% + 4rem);
    padding: 4rem 4rem 4rem 0; }
    @media (max-width: 1200px) {
      .actualite-accueil .actualite-image-wrapper {
        width: 100%;
        padding: 0; }
        .actualite-accueil .actualite-image-wrapper img {
          width: 100%; } }

.artiste-accueil {
  position: relative;
  overflow: hidden; }
  @media (max-width: 768px) {
    .artiste-accueil {
      padding-top: 6rem; } }
  .artiste-accueil .flex-container, .artiste-accueil .flex-container--row,
  .artiste-accueil .flex-row, .artiste-accueil .flex-container--column,
  .artiste-accueil .flex-column, .artiste-accueil .flex-container--row-reverse,
  .artiste-accueil .flex-row-reverse, .artiste-accueil .flex-container--column-reverse,
  .artiste-accueil .flex-column-reverse {
    align-items: center;
    margin-top: 6rem; }
    @media (max-width: 768px) {
      .artiste-accueil .flex-container, .artiste-accueil .flex-container--row,
      .artiste-accueil .flex-row, .artiste-accueil .flex-container--column,
      .artiste-accueil .flex-column, .artiste-accueil .flex-container--row-reverse,
      .artiste-accueil .flex-row-reverse, .artiste-accueil .flex-container--column-reverse,
      .artiste-accueil .flex-column-reverse {
        margin-top: 3rem; } }
  .artiste-accueil .artiste-image-wrapper {
    width: calc(50% + 4rem); }
    @media (max-width: 768px) {
      .artiste-accueil .artiste-image-wrapper {
        order: 1;
        width: 100%; }
        .artiste-accueil .artiste-image-wrapper img {
          width: 100%; } }
  .artiste-accueil .artiste-texte-wrapper {
    width: calc(50% - 4rem);
    padding: 0 2rem 0 4rem; }
    @media (max-width: 768px) {
      .artiste-accueil .artiste-texte-wrapper {
        width: 100%;
        padding: 0 0 3rem; } }
  .artiste-accueil h3 {
    position: relative;
    margin: 0 0 6rem;
    font-weight: 300;
    font-size: 5rem;
    line-height: 5rem; }
    @media (max-width: 992px) {
      .artiste-accueil h3 {
        margin-bottom: 4rem;
        font-size: 4rem;
        line-height: 4rem; } }
    @media (max-width: 768px) {
      .artiste-accueil h3 {
        margin-bottom: 2rem; } }
    @media (max-width: 576px) {
      .artiste-accueil h3 {
        font-size: 3rem;
        line-height: 3rem; } }
    .artiste-accueil h3:before {
      position: absolute;
      left: -7rem;
      top: 50%;
      content: '—';
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
  .artiste-accueil .artiste-texte-link {
    display: inline-block;
    margin: 6rem 0 0;
    padding: .2rem .7rem;
    font-size: 1.8rem;
    letter-spacing: .1rem;
    text-transform: uppercase;
    color: #fff;
    background-color: #e94628;
    transition: background-color .3s ease-in-out; }
    @media (max-width: 992px) {
      .artiste-accueil .artiste-texte-link {
        margin-top: 4rem; } }
    @media (max-width: 768px) {
      .artiste-accueil .artiste-texte-link {
        margin-top: 2rem; } }
    .artiste-accueil .artiste-texte-link svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: .3rem;
      vertical-align: baseline;
      fill: #fff; }
    .artiste-accueil .artiste-texte-link:hover {
      background-color: #333030; }
  .artiste-accueil .logo-bernard-bavaud-poincon {
    position: absolute;
    right: -10rem;
    bottom: -10rem;
    width: 80rem;
    height: 74.5rem;
    fill: rgba(235, 229, 229, 0.3);
    z-index: -1; }
    @media (max-width: 768px) {
      .artiste-accueil .logo-bernard-bavaud-poincon {
        display: none; } }

.oeuvres-content-intro .texte-wrapper {
  margin-top: 4rem; }
  @media (max-width: 768px) {
    .oeuvres-content-intro .texte-wrapper {
      margin-top: 2rem; } }
  .oeuvres-content-intro .texte-wrapper p:first-of-type {
    margin-top: 1rem; }
  .oeuvres-content-intro .texte-wrapper p:last-of-type, .oeuvres-content-intro .texte-wrapper blockquote:last-of-type {
    margin-bottom: 0; }

.oeuvres-content {
  margin-bottom: 10rem;
  padding: 0 1rem; }
  @media (max-width: 1200px) {
    .oeuvres-content {
      margin-bottom: 7rem; } }
  @media (max-width: 768px) {
    .oeuvres-content {
      margin-bottom: 4rem; } }
  @media (max-width: 480px) {
    .oeuvres-content {
      margin-bottom: 3rem; } }
  .oeuvres-content > .flex-container, .oeuvres-content > .flex-container--row,
  .oeuvres-content > .flex-row, .oeuvres-content > .flex-container--column,
  .oeuvres-content > .flex-column, .oeuvres-content > .flex-container--row-reverse,
  .oeuvres-content > .flex-row-reverse, .oeuvres-content > .flex-container--column-reverse,
  .oeuvres-content > .flex-column-reverse {
    width: 100%; }

.oeuvre {
  position: relative;
  width: 25%;
  padding: 1rem; }
  @media (max-width: 2020px) {
    .oeuvre {
      width: 33.33%; } }
  @media (max-width: 992px) {
    .oeuvre {
      width: 50%; } }
  @media (max-width: 576px) {
    .oeuvre {
      width: 100%; } }
  .oeuvre:hover .oeuve-texte-wrapper {
    opacity: 1; }
  .oeuvre .oeuvre-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2; }
  .oeuvre .oeuve-texte-wrapper {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    padding: 1.2rem 1.5rem;
    opacity: 0;
    box-shadow: inset 0 0 0 0.3rem #e94628;
    background-color: rgba(255, 255, 255, 0.8);
    transition: opacity .2s ease-in-out;
    z-index: 1; }
    @media (max-width: 992px) {
      .oeuvre .oeuve-texte-wrapper h2 {
        font-size: 2.3rem; }
      .oeuvre .oeuve-texte-wrapper h3 {
        font-size: 2rem; } }
    .oeuvre .oeuve-texte-wrapper h3 {
      margin: -.5rem 0 0; }
  .oeuvre .oeuvre-pic {
    width: 100%; }

.oeuvre-content .titles-wrapper {
  font-size: 3.8rem; }
  @media (max-width: 992px) {
    .oeuvre-content .titles-wrapper {
      font-size: 2.8rem; } }
  @media (max-width: 576px) {
    .oeuvre-content .titles-wrapper {
      font-size: 1.8rem; } }
  .oeuvre-content .titles-wrapper h1, .oeuvre-content .titles-wrapper h2 {
    display: inline;
    margin: 0; }
  .oeuvre-content .titles-wrapper > span {
    margin: 0 1.5rem; }
  .oeuvre-content .titles-wrapper h1 {
    text-transform: unset;
    letter-spacing: 0;
    font-size: 3.8rem;
    font-weight: 300;
    color: #333030; }
    @media (max-width: 992px) {
      .oeuvre-content .titles-wrapper h1 {
        font-size: 3rem; } }
    @media (max-width: 576px) {
      .oeuvre-content .titles-wrapper h1 {
        font-size: 2.3rem; } }
  .oeuvre-content .titles-wrapper h2 {
    text-transform: uppercase; }
    .oeuvre-content .titles-wrapper h2 span {
      padding: 0 1rem;
      font-weight: 700;
      font-size: 3rem;
      color: #fff;
      background-color: #e94628; }
      @media (max-width: 992px) {
        .oeuvre-content .titles-wrapper h2 span {
          font-size: 2.5rem; } }
      @media (max-width: 576px) {
        .oeuvre-content .titles-wrapper h2 span {
          font-size: 2rem; } }

.oeuvre-content .fil-ariane {
  margin: 2rem 0 0;
  text-transform: uppercase;
  font-size: 1.2rem; }
  .oeuvre-content .fil-ariane a {
    padding: 0 .5rem;
    color: rgba(51, 48, 48, 0.7); }
    .oeuvre-content .fil-ariane a:hover {
      color: #161414; }
    .oeuvre-content .fil-ariane a:first-of-type {
      padding-left: 0; }
  .oeuvre-content .fil-ariane span {
    padding-left: .5rem;
    color: #e94628; }

.oeuvre-content .flex-container, .oeuvre-content .flex-container--row,
.oeuvre-content .flex-row, .oeuvre-content .flex-container--column,
.oeuvre-content .flex-column, .oeuvre-content .flex-container--row-reverse,
.oeuvre-content .flex-row-reverse, .oeuvre-content .flex-container--column-reverse,
.oeuvre-content .flex-column-reverse {
  margin-top: 6rem; }
  @media (max-width: 768px) {
    .oeuvre-content .flex-container, .oeuvre-content .flex-container--row,
    .oeuvre-content .flex-row, .oeuvre-content .flex-container--column,
    .oeuvre-content .flex-column, .oeuvre-content .flex-container--row-reverse,
    .oeuvre-content .flex-row-reverse, .oeuvre-content .flex-container--column-reverse,
    .oeuvre-content .flex-column-reverse {
      justify-content: center;
      margin-top: 3rem; } }
  @media (max-width: 480px) {
    .oeuvre-content .flex-container, .oeuvre-content .flex-container--row,
    .oeuvre-content .flex-row, .oeuvre-content .flex-container--column,
    .oeuvre-content .flex-column, .oeuvre-content .flex-container--row-reverse,
    .oeuvre-content .flex-row-reverse, .oeuvre-content .flex-container--column-reverse,
    .oeuvre-content .flex-column-reverse {
      margin-top: 2rem; } }

.oeuvre-content .texte-wrapper {
  width: calc(50% + 4rem); }
  @media (max-width: 768px) {
    .oeuvre-content .texte-wrapper {
      width: 100%;
      margin-bottom: 3rem; } }
  .oeuvre-content .texte-wrapper .texte {
    width: 100%;
    max-width: 56rem;
    margin: 0 0 0 auto;
    padding: 0 4rem 0 2rem; }
  .oeuvre-content .texte-wrapper .description {
    margin-bottom: 4rem; }
    @media (max-width: 768px) {
      .oeuvre-content .texte-wrapper .description {
        margin-bottom: 3rem; } }
    @media (max-width: 480px) {
      .oeuvre-content .texte-wrapper .description {
        margin-bottom: 2rem; } }
  .oeuvre-content .texte-wrapper blockquote {
    padding-left: 4rem;
    font-size: 1.8rem;
    z-index: -1; }
    @media (max-width: 992px) {
      .oeuvre-content .texte-wrapper blockquote {
        font-size: 1.6rem; } }
    @media (max-width: 768px) {
      .oeuvre-content .texte-wrapper blockquote {
        padding-left: 3rem; } }
    @media (max-width: 480px) {
      .oeuvre-content .texte-wrapper blockquote {
        padding-left: 2rem; } }
    .oeuvre-content .texte-wrapper blockquote:before {
      top: 0;
      font-size: 12rem; }
      @media (max-width: 768px) {
        .oeuvre-content .texte-wrapper blockquote:before {
          font-size: 10rem; } }
      @media (max-width: 480px) {
        .oeuvre-content .texte-wrapper blockquote:before {
          left: -1rem;
          font-size: 6rem; } }
  .oeuvre-content .texte-wrapper .info {
    margin: 2rem 0;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.4rem; }
    .oeuvre-content .texte-wrapper .info svg {
      margin: 0 .2rem 0 -.3rem;
      fill: #333030; }
  .oeuvre-content .texte-wrapper .link-facebook {
    display: block;
    margin-top: 4rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #e94628;
    transition: opacity .3s ease-in-out; }
    @media (max-width: 768px) {
      .oeuvre-content .texte-wrapper .link-facebook {
        margin-top: 3rem; } }
    @media (max-width: 480px) {
      .oeuvre-content .texte-wrapper .link-facebook {
        margin-top: 2rem; } }
    .oeuvre-content .texte-wrapper .link-facebook:hover {
      opacity: .8; }
    .oeuvre-content .texte-wrapper .link-facebook svg {
      margin-right: 1rem;
      fill: #e94628; }
    .oeuvre-content .texte-wrapper .link-facebook span {
      vertical-align: top; }

.oeuvre-content .pics-wrapper {
  width: calc(50% - 4rem);
  padding-left: 4rem; }
  @media (max-width: 768px) {
    .oeuvre-content .pics-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: -1rem 0;
      padding: 0; } }
  .oeuvre-content .pics-wrapper .oeuvre-pic-big {
    display: inline-block;
    outline: none;
    margin-bottom: 2rem; }
    @media (max-width: 768px) {
      .oeuvre-content .pics-wrapper .oeuvre-pic-big {
        width: 50%;
        margin: 0;
        padding: 1rem; } }
    @media (max-width: 480px) {
      .oeuvre-content .pics-wrapper .oeuvre-pic-big {
        width: 100%;
        padding: 1rem 0; } }
    .oeuvre-content .pics-wrapper .oeuvre-pic-big:last-of-type {
      margin-bottom: 0; }
  .oeuvre-content .pics-wrapper .oeuvre-single-pic {
    width: 100%; }

.artiste {
  overflow: hidden; }
  .artiste h2 {
    margin-bottom: 2rem; }
    @media (max-width: 480px) {
      .artiste h2 {
        margin-bottom: 1rem; } }
  .artiste a.underline:before {
    background-color: rgba(99, 96, 96, 0.3); }
  .artiste a.underline:hover:before {
    background-color: #333030; }
  .artiste .contenu-wrapper {
    position: relative;
    align-items: flex-start;
    margin-bottom: 5rem;
    z-index: 1; }
    .artiste .contenu-wrapper:last-of-type {
      margin-bottom: 0; }
  .artiste .texte-wrapper {
    width: calc(50% - 4rem); }
    .artiste .texte-wrapper.texte-left {
      padding-right: 4rem; }
    .artiste .texte-wrapper.texte-right {
      padding-left: 4rem; }
    @media (max-width: 768px) {
      .artiste .texte-wrapper {
        width: 100%; }
        .artiste .texte-wrapper.texte-left, .artiste .texte-wrapper.texte-right {
          padding: 0; } }
  .artiste .legende {
    margin: 0;
    padding: .3rem .7rem;
    text-align: right;
    font-style: italic;
    font-size: 1.3rem;
    background-color: rgba(235, 229, 229, 0.3); }
  .artiste .img-wrapper {
    width: calc(50% + 4rem);
    outline: none;
    border: 0.2rem solid rgba(235, 229, 229, 0.3); }
    @media (max-width: 768px) {
      .artiste .img-wrapper {
        order: 1;
        width: 100%;
        margin-top: 2rem; } }
    .artiste .img-wrapper img {
      width: 100%; }
  .artiste .artiste-babybel {
    position: relative;
    color: #ebe5e5;
    background-color: #e94628; }
    .artiste .artiste-babybel h2 {
      color: #fff; }
    .artiste .artiste-babybel a.underline {
      color: #ebe5e5; }
      .artiste .artiste-babybel a.underline:before {
        background-color: rgba(255, 255, 255, 0.3); }
      .artiste .artiste-babybel a.underline:hover {
        color: #fff; }
        .artiste .artiste-babybel a.underline:hover:before {
          background-color: #333030; }
    .artiste .artiste-babybel .contenu-wrapper .texte-wrapper {
      width: calc(50% + 12rem); }
      @media (max-width: 768px) {
        .artiste .artiste-babybel .contenu-wrapper .texte-wrapper {
          width: 100%; } }
    .artiste .artiste-babybel .contenu-wrapper .img-wrapper {
      width: calc(50% - 12rem);
      border: none; }
      @media (max-width: 768px) {
        .artiste .artiste-babybel .contenu-wrapper .img-wrapper {
          width: 100%; } }
      .artiste .artiste-babybel .contenu-wrapper .img-wrapper .legende {
        width: 91.45%;
        color: #fff; }
    .artiste .artiste-babybel blockquote:before {
      left: -1rem;
      color: rgba(235, 229, 229, 0.2); }
  .artiste .logo-bernard-bavaud-poincon {
    position: absolute;
    top: -37rem;
    right: -10rem;
    width: 80rem;
    height: 74.5rem;
    fill: rgba(235, 229, 229, 0.15); }

.presse .flex-container, .presse .flex-container--row,
.presse .flex-row, .presse .flex-container--column,
.presse .flex-column, .presse .flex-container--row-reverse,
.presse .flex-row-reverse, .presse .flex-container--column-reverse,
.presse .flex-column-reverse {
  padding: 0 1rem 10rem; }
  @media (max-width: 1200px) {
    .presse .flex-container, .presse .flex-container--row,
    .presse .flex-row, .presse .flex-container--column,
    .presse .flex-column, .presse .flex-container--row-reverse,
    .presse .flex-row-reverse, .presse .flex-container--column-reverse,
    .presse .flex-column-reverse {
      padding-bottom: 7rem; } }
  @media (max-width: 768px) {
    .presse .flex-container, .presse .flex-container--row,
    .presse .flex-row, .presse .flex-container--column,
    .presse .flex-column, .presse .flex-container--row-reverse,
    .presse .flex-row-reverse, .presse .flex-container--column-reverse,
    .presse .flex-column-reverse {
      padding-bottom: 4rem; } }
  @media (max-width: 480px) {
    .presse .flex-container, .presse .flex-container--row,
    .presse .flex-row, .presse .flex-container--column,
    .presse .flex-column, .presse .flex-container--row-reverse,
    .presse .flex-row-reverse, .presse .flex-container--column-reverse,
    .presse .flex-column-reverse {
      padding-bottom: 3rem; } }

.presse h1 {
  margin-bottom: 0; }

.presse .presse-pic {
  width: 16.66%;
  padding: 1rem;
  outline: none;
  border: .2rem solid transparent;
  transition: border-color .3s ease-in-out; }
  @media (max-width: 1200px) {
    .presse .presse-pic {
      width: 33.33%; } }
  @media (max-width: 768px) {
    .presse .presse-pic {
      width: 50%; } }
  @media (max-width: 480px) {
    .presse .presse-pic {
      width: 100%; } }
  .presse .presse-pic:hover {
    border-color: #e94628; }

.presse .presse-vign {
  width: 100%; }

.actualite-date {
  position: relative;
  margin: 2rem 0 3rem;
  text-transform: uppercase;
  font-size: 1.2rem; }
  .actualite-date span {
    padding: .2rem .5rem 0;
    color: #fff;
    background-color: #636060;
    transition: all .3s ease-in-out; }

.body-actualites main {
  overflow: hidden; }

.actualites .actualite-single {
  position: relative;
  margin-bottom: 4rem;
  transition: all .3s ease-in-out; }
  @media (max-width: 1200px) {
    .actualites .actualite-single {
      align-items: center; } }
  @media (min-width: 993px) {
    .actualites .actualite-single:nth-of-type(even) .actualite-single-content {
      order: 1; } }
  .actualites .actualite-single .actualite-date {
    margin: 2rem 0 0; }
    @media (max-width: 576px) {
      .actualites .actualite-single .actualite-date {
        margin-top: .5rem; } }
  .actualites .actualite-single .txt-wrapper {
    margin-top: 3rem; }
    @media (max-width: 992px) {
      .actualites .actualite-single .txt-wrapper {
        margin-top: 2rem; } }
    @media (max-width: 576px) {
      .actualites .actualite-single .txt-wrapper {
        margin-top: 1rem; } }
  .actualites .actualite-single:last-of-type {
    margin-bottom: 0; }
  .actualites .actualite-single:hover {
    color: #ebe5e5;
    background-color: #e94628; }
    .actualites .actualite-single:hover h2, .actualites .actualite-single:hover .fake-btn {
      color: #fff; }
      .actualites .actualite-single:hover h2:after, .actualites .actualite-single:hover .fake-btn:after {
        background-color: rgba(255, 255, 255, 0.3); }
    .actualites .actualite-single:hover .actualite-date span {
      color: #e94628;
      background-color: #fff; }

.actualites .actualite-single-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.actualites .actualite-single-img {
  width: calc(50% + 4rem); }
  @media (max-width: 992px) {
    .actualites .actualite-single-img {
      width: 100%; }
      .actualites .actualite-single-img img {
        width: 100%; } }

.actualites .actualite-single-content {
  width: calc(50% - 4rem);
  padding: 4rem; }
  @media (max-width: 1200px) {
    .actualites .actualite-single-content {
      padding: 2rem; } }
  @media (max-width: 992px) {
    .actualites .actualite-single-content {
      width: 100%;
      padding: 2rem 2rem 3rem; } }

.actualites h2, .actualites .actualite-single-date, .actualites .fake-btn {
  transition: color .3s ease-in-out; }

.actualites h2 {
  position: relative; }
  .actualites h2:before {
    position: absolute;
    left: -5rem;
    top: 50%;
    content: '—';
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
    @media (max-width: 1200px) {
      .actualites h2:before {
        left: -3rem; } }

.actualites .fake-btn {
  display: block;
  margin-top: 4rem;
  text-transform: uppercase;
  font-weight: 700; }
  @media (max-width: 992px) {
    .actualites .fake-btn {
      margin-top: 2rem; } }

.actualite {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 39.5rem); }
  .actualite h1 {
    position: relative;
    margin: 0;
    letter-spacing: 0;
    text-transform: none;
    color: #333030; }
    .actualite h1:before {
      position: absolute;
      left: -5rem;
      top: 50%;
      content: '—';
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
      @media (max-width: 1200px) {
        .actualite h1:before {
          content: none; } }
  .actualite a {
    color: #e94628; }
    .actualite a:hover {
      color: #636060; }
  .actualite .container-small {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 2rem; }
    @media (max-width: 768px) {
      .actualite .container-small {
        margin-top: 1rem; } }
    @media (max-width: 480px) {
      .actualite .container-small {
        margin-top: 0; } }
  .actualite .actualite-date span {
    background-color: #e94628; }
  .actualite img {
    width: 100%;
    margin: 2rem 0; }
  .actualite .buttons-wrapper {
    justify-content: flex-end;
    align-items: center;
    margin: auto 0 0; }
    @media (max-width: 480px) {
      .actualite .buttons-wrapper {
        flex-direction: column;
        align-items: flex-end;
        justify-content: center; } }
  .actualite .link-facebook, .actualite .basic-btn {
    margin-top: 4rem;
    font-size: 1.8rem;
    letter-spacing: .1rem;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      .actualite .link-facebook, .actualite .basic-btn {
        margin-top: 2rem; } }
    @media (max-width: 576px) {
      .actualite .link-facebook, .actualite .basic-btn {
        font-size: 1.4rem; } }
    @media (max-width: 480px) {
      .actualite .link-facebook, .actualite .basic-btn {
        margin-top: 0; } }
  .actualite .link-facebook {
    align-items: center;
    margin-right: 2rem;
    color: #e94628;
    transition: opacity .3s ease-in-out; }
    @media (max-width: 480px) {
      .actualite .link-facebook {
        margin: 0 0 2rem 0; } }
    .actualite .link-facebook svg {
      margin: 0 .5rem .1rem 0;
      fill: #e94628; }
    .actualite .link-facebook:hover {
      opacity: .8; }
  .actualite .basic-btn svg {
    margin-right: .3rem;
    vertical-align: baseline;
    fill: #fff; }
  .actualite .basic-btn span {
    padding: .2rem .7rem;
    color: #fff;
    background-color: #161414;
    transition: background-color .3s ease-in-out; }
  .actualite .basic-btn:hover span {
    background-color: #e94628; }
