// Style page Artiste

  .artiste {
    overflow: hidden;

    h2 {
      margin-bottom: 2rem;

      @media (max-width: $tiny) {
        margin-bottom: 1rem;
      }
    }

    a.underline {
      &:before {
        background-color: rgba($gris-med, .3);
      }

      &:hover:before {
        background-color: $anthracite;
      }
    }

    .contenu-wrapper {
      position: relative;
      align-items: flex-start;
      margin-bottom: 5rem;
      z-index: 1;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .texte-wrapper {
      width: calc(50% - 4rem);

      &.texte-left {
        padding-right: 4rem;
      }

      &.texte-right {
        padding-left: 4rem;
      }

      @media (max-width: $medium) {
        width: 100%;

        &.texte-left, &.texte-right {
          padding: 0;
        }
      }
    }

    .legende {
      margin: 0;
      padding: .3rem .7rem;
      text-align: right;
      font-style: italic;
      font-size: 1.3rem;
      background-color: rgba($gris-clair, .3);
    }

    .img-wrapper {
      width: calc(50% + 4rem);
      outline: none;
      border: .2rem solid rgba($gris-clair, .3);

      @media (max-width: $medium) {
        order: 1;
        width: 100%;
        margin-top: 2rem;
      }

      img {
        width: 100%;
      }
    }

    .artiste-babybel {
      position: relative;
      color: $gris-clair;
      background-color: $rouge;

      h2 {
        color: $blanc;
      }

      a.underline {
        color: $gris-clair;

        &:before {
          background-color: rgba($blanc, .3);
        }

        &:hover {
          color: $blanc;

          &:before {
            background-color: $anthracite;
          }
        }
      }

      .contenu-wrapper {
        .texte-wrapper {
          width: calc(50% + 12rem);

          @media (max-width: $medium) {
            width: 100%;
          }
        }

        .img-wrapper {
          width: calc(50% - 12rem);
          border: none;

          @media (max-width: $medium) {
            width: 100%;
          }

          .legende {
            width: 91.45%;
            color: $blanc;
          }
        }
      }

      blockquote:before {
        left: -1rem;
        color: rgba($gris-clair, .2);
      }
    }

    .logo-bernard-bavaud-poincon {
      position: absolute;
      top: -37rem;
      right: -10rem;
      width: 80rem;
      height: 74.5rem;
      fill: rgba($gris-clair, .15);
    }
  }
