// Style page Revue de presse

  .presse {
    .flex-container {
      padding: 0 1rem 10rem;

      @media (max-width: $extra-large) {
        padding-bottom: 7rem;
      }

      @media (max-width: $medium) {
        padding-bottom: 4rem;
      }

      @media (max-width: $tiny) {
        padding-bottom: 3rem;
      }
    }

    h1 {
      margin-bottom: 0;
    }

    .presse-pic {
      width: 16.66%;
      padding: 1rem;
      outline: none;
      border: .2rem solid transparent;
      transition: border-color .3s ease-in-out;

      @media (max-width: $extra-large) {
        width: 33.33%;
      }

      @media (max-width: $medium) {
        width: 50%;
      }

      @media (max-width: $tiny) {
        width: 100%;
      }

      &:hover {
        border-color: $rouge;
      }
    }

    .presse-vign {
      width: 100%;
    }
  }
