// Style page d'accueil

  .accueil {
    h2 {
      margin-left: -2rem;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 4.5rem;
      letter-spacing: .3rem;
      color: $blanc;

      @media (max-width: $large) {
        font-size: 3.5rem;
      }

      @media (max-width: $small) {
        font-size: 2.5rem;
      }

      span {
        padding: 0 1rem;
        background-color: $anthracite;
      }
    }
  }

  // Style bloc 3 photos

  .intro-accueil-wrapper {
    position: relative;
  }

  .intro-texte-wrapper {
    margin-bottom: 10rem;

    @media (max-width: $extra-large) {
      margin-bottom: 7rem;
    }

    @media (max-width: $medium) {
      margin-bottom: 4rem;
    }

    @media (max-width: $tiny) {
      margin-bottom: 3rem;
    }

    p {
      &:first-of-type {
        font-size: 1.2rem;
        text-align: right;
      }

      &:last-of-type {
        margin: 5rem 0 0;

        @media (max-width: $medium) {
          margin-top: 3rem;
        }
      }
    }
  }

  .intro-titre-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 2rem;
    text-align: center;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;

    h1 {
      margin: 0;
    }

    .logo-bernard-bavaud {
      position: relative;
      width: 50rem;
      height: 17.7rem;
      margin: 0 auto;
      z-index: 2;

      @media (max-width: $large) {
        width: 25rem;
        height: 8.8rem;
      }

      .fill-noir {
        fill: $blanc;
      }
    }

    h2 {
      margin: -3rem -7rem 0 0;
      line-height: 3.5rem;

      @media (max-width: $large) {
        margin: -2rem -6rem 0 0;
        line-height: 2.5rem;
      }

      @media (max-width: $tiny) {
        margin: -2rem 0 0;
      }

      span {
        font-size: 3rem;
        color: $rouge;
        background-color: $noir;

        @media (max-width: $large) {
          font-size: 2rem;
        }
      }
    }
  }

  .arrow-down {
    position: absolute;
    left: 50%;
    bottom: 1rem;
    padding: 1rem;
    z-index: 2;
    transform: translateX(-50%);
    animation: float 1.5s infinite ease-in-out;

    svg {
      fill: $blanc;

      @media (max-width: $medium) {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .intro-accueil {
    position: relative;

    @media (min-width: $xx-large + 1) {
      height: calc(100vh - 10rem);
    }

    @media (max-width: $xx-large) {
      height: 0;
      padding: 0 0 46.68%;
    }

    @media (max-width: $medium) {
      padding: 0 0 70%;
    }

    @media (max-width: $tiny) {
      padding: 0 0 100%;
    }

    .buste-accueil {
      position: relative;
      width: 33.33%;
      height: 100%;
      background: {
        size: cover;
        repeat: no-repeat;
        position: center center;
      }

      @media (max-width: $xx-large) {
        position: absolute;
        top: 0;
        left: 0;

        &:nth-of-type(2) {
          left: 33.33%;
        }

        &:nth-of-type(3) {
          left: 66.66%;
        }
      }

      @media (max-width: $medium) {
        width: 50%;

        &:nth-of-type(2) {
          left: 50%;
        }

        &:nth-of-type(3) {
          display: none;
        }
      }

      @media (max-width: $tiny) {
        width: 100%;

        &:nth-of-type(2) {
          display: none;
        }
      }

      & > span {
        position: absolute;
        left: 2rem;
        right: 2rem;
        bottom: -1rem;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: .1rem;
        opacity: 0;
        transition: all .3s ease-in-out;

        @media (max-width: $medium) {
          left: 1rem;
          right: 1rem;
          bottom: -1.5rem;
          font-size: 1.1rem;
        }

        span {
          padding: 0 .5rem;
          background-color: $gris-clair;
        }
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        background-color: rgba($noir, .7);
        transition: background-color .3s ease-in-out;
      }

      &:hover {
        span {
          bottom: 1.5rem;
          opacity: 1;

          @media (max-width: $medium) {
            bottom: 1rem;
          }
        }

        &:before {
          background-color: transparent;
        }
      }
    }
  }

  // Style catégories

  .categories-accueil .flex-container {
    margin: 4rem -4rem -4rem;

    @media (max-width: $xx-large) {
      margin: 4rem -2rem -4rem;
    }

    @media (max-width: $extra-large) {
      margin: 4rem 1rem 1rem;
    }
  }

  .categorie-accueil, .all-categories-accueil {
    width: calc(33.33% - 8rem);
    height: 33.28rem;
    margin: 4rem;
    padding: .7rem 1rem;
    border: .2rem solid $rouge;

    @media (max-width: $extra-large) {
      width: calc(33.33% - 5rem);
      margin: 2.5rem;
    }

    @media (max-width: $large) {
      width: calc(50% - 8rem);
      margin: 4rem;
    }

    @media (max-width: $medium) {
      width: calc(50% - 4rem);
      margin: 2rem;
    }

    @media (max-width: $small) {
      width: 100%;
      height: 40rem;
      margin: 0 0 4rem;

      &.categorie-accueil-nusdepoche, &.categorie-accueil-academique {
        z-index: 2;
      }

      &.all-categories-accueil {
        margin-bottom: 0;
      }
    }

    @media (max-width: $tiny) {
      height: 30rem;
      margin: 0 0 3rem;
    }

    h3 {
      margin: 0;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 3rem;
      letter-spacing: .1rem;
      line-height: 3.5rem;
      color: $noir;
      transition: color .3s ease-in-out;

      @media (max-width: $large) {
        font-size: 2.5rem;
      }

      @media (max-width: $small) {
        font-size: 2rem;
      }
    }
  }

  .categorie-accueil {
    position: relative;
    transition: border-color .3s ease-in-out;

    &:hover {
      border-color: transparent;

      &:before {
        opacity: 1;
      }

      h3 {
        color: $blanc;
      }
    }

    &:before {
      position: absolute;
      top: -.2rem;
      left: -.2rem;
      right: -.2rem;
      bottom: -.2rem;
      content: '';
      mix-blend-mode: multiply;
      opacity: 0;
      background-color: rgba($rouge, .8);
      transition: opacity .3s ease-in-out;
      z-index: 2;
    }

    .categorie-accueil-link {
      position: absolute;
      top: -4rem;
      left: -4rem;
      right: -4rem;
      bottom: -4rem;
      z-index: 4;

      @media (max-width: $xx-large) {
        top: -2rem;
        left: -2rem;
        right: -2rem;
        bottom: -2rem;
      }
    }

    h3 {
      position: absolute;
      top: .7rem;
      left: 1rem;
      right: 1rem;
      z-index: 3;
    }

    .categorie-accueil-image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1;

      @media (max-width: $tiny) {
        max-width: 24rem;
      }
    }

    &.categorie-accueil-bustesportraits img {
      left: calc(50% - 1rem);
      width: 27rem;
    }

    &.categorie-accueil-sculptures img {
      top: calc(50% + 3rem);
    }

    &.categorie-accueil-gravures img {
      top: calc(50% - 1rem);
    }

    &.categorie-accueil-academique img {
      width: 26rem;
    }
  }

  .all-categories-accueil {
    background-color: $rouge;
    transition: background-color .3s ease-in-out;

    h3 {
      display: inline;
    }

    svg {
      width: 2rem;
      height: 2rem;
      margin-left: .5rem;
      fill: $noir;
      transition: fill .3s ease-in-out -.3s;
    }

    &:hover {
      background-color: transparent;

      h3 {
        color: $rouge;
      }

      svg {
        fill: $rouge;
      }
    }
  }

  // Style dernière actualité

  .actualite-accueil {
    position: relative;
    margin: 0 2rem;

    .actualite-texte-wrapper {
      flex-direction: column;
      align-items: flex-end;
      width: calc(50% - 4rem);

      @media (max-width: $extra-large) {
        width: 100%;
      }
    }

    .actualite-texte {
      position: relative;

      @media (min-width: $extra-large + 1) {
        flex-grow: 1;
        max-width: 56rem;
        height: 100%;
        margin: 0 0 0 auto;
        padding: 0 4rem 0 2rem;
      }

      @media (max-width: $extra-large) {
        padding: 2rem 2rem 3rem;
      }

      h2 {
        margin: -1rem 0 0 -2rem;
        color: $rouge;

        @media (min-width: $extra-large + 1) {
          width: 150%;
        }

        @media (max-width: $extra-large) {
          margin: -3rem 0 0 -2rem;
        }
      }

      h3 {
        position: relative;
        width: 150%;
        margin: 10rem 0 3rem;
        font-weight: 300;
        font-size: 5rem;
        color: $blanc;

        @media (min-width: $extra-large + 1) {
          &:before {
            position: absolute;
            left: -7rem;
            top: 50%;
            content: '—';
            transform: translateY(-50%);
          }
        }

        @media (max-width: $extra-large) {
          width: 100%;
          margin-top: 5rem;
        }

        @media (max-width: $large) {
          margin-bottom: 1.5rem;
          font-size: 4rem;
        }

        @media (max-width: $medium) {
          margin-top: 4rem;
        }

        @media (max-width: $small) {
          font-size: 3rem;
        }

        @media (max-width: $tiny) {
          margin-top: 3rem;
        }
      }
    }

    .accueil-actualite-date {
      margin: 0 0 4rem;

      @media (max-width: $large) {
        margin-bottom: 2rem;
        font-size: 1.2rem;
        font-weight: 700;
      }

      span {
        padding: .2rem .5rem 0;
        color: $noir;
        background-color: $blanc;
      }
    }

    .lien-actualite {
      position: relative;
      display: block;
      margin: 4rem 0 6rem;
      text-transform: uppercase;
      font-weight: 700;

      @media (max-width: $extra-large) {
        margin: 4rem 0 0;
      }

      @media (max-width: $tiny) {
        margin-top: 2rem;
      }
    }

    .lien-actualites-all {
      position: absolute;
      right: 4rem;
      bottom: -1rem;

      @media (max-width: $tiny) {
        left: 0;
        right: 0;
      }

      span {
        padding: .3rem .6rem;
        text-transform: uppercase;
        letter-spacing: .2rem;
        background-color: $rouge;
        transition: background-color .3s ease-in-out;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: .3rem;
        vertical-align: baseline;
        fill: $blanc;
      }

      &:hover {
        color: $blanc;

        span {
          background-color: $anthracite;
        }
      }
    }

    .actualite-image-wrapper {
      width: calc(50% + 4rem);
      padding: 4rem 4rem 4rem 0;

      @media (max-width: $extra-large) {
        width: 100%;
        padding: 0;

        img {
          width: 100%;
        }
      }
    }
  }

  // Style lien artiste

  .artiste-accueil {
    position: relative;
    overflow: hidden;

    @media (max-width: $medium) {
      padding-top: 6rem;
    }

    .flex-container {
      align-items: center;
      margin-top: 6rem;

      @media (max-width: $medium) {
        margin-top: 3rem;
      }
    }

    .artiste-image-wrapper {
      width: calc(50% + 4rem);

      @media (max-width: $medium) {
        order: 1;
        width: 100%;

        img {
          width: 100%;
        }
      }
    }

    .artiste-texte-wrapper {
      width: calc(50% - 4rem);
      padding: 0 2rem 0 4rem;

      @media (max-width: $medium) {
        width: 100%;
        padding: 0 0 3rem;
      }
    }

    h3 {
      position: relative;
      margin: 0 0 6rem;
      font-weight: 300;
      font-size: 5rem;
      line-height: 5rem;

      @media (max-width: $large) {
        margin-bottom: 4rem;
        font-size: 4rem;
        line-height: 4rem;
      }

      @media (max-width: $medium) {
        margin-bottom: 2rem;
      }

      @media (max-width: $small) {
        font-size: 3rem;
        line-height: 3rem;
      }

      &:before {
        position: absolute;
        left: -7rem;
        top: 50%;
        content: '—';
        transform: translateY(-50%);
      }
    }

    .artiste-texte-link {
      display: inline-block;
      margin: 6rem 0 0;
      padding: .2rem .7rem;
      font-size: 1.8rem;
      letter-spacing: .1rem;
      text-transform: uppercase;
      color: $blanc;
      background-color: $rouge;
      transition: background-color .3s ease-in-out;

      @media (max-width: $large) {
        margin-top: 4rem;
      }

      @media (max-width: $medium) {
        margin-top: 2rem;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: .3rem;
        vertical-align: baseline;
        fill: $blanc;
      }

      &:hover {
        background-color: $anthracite;
      }
    }

    .logo-bernard-bavaud-poincon {
      position: absolute;
      right: -10rem;
      bottom: -10rem;
      width: 80rem;
      height: 74.5rem;
      fill: rgba($gris-clair, .3);
      z-index: -1;

      @media (max-width: $medium) {
        display: none;
      }
    }
  }
