
	// Pied de page

  	footer {
      position: relative;
      overflow: hidden;
      color: $gris-clair;
      background-color: $anthracite;

      .logo-bernard-bavaud-texte {
        position: absolute;
        left: -10rem;
        top: 2rem;
        width: 140rem;
        fill: rgba($gris-med, .1);
      }

      .container {
        justify-content: center;
        position: relative;
        text-align: center;
        z-index: 1;
      }

      .footer-title {
        margin: 0 0 5rem;
        text-transform: uppercase;
        letter-spacing: .2rem;
        font-size: 2.5rem;
        font-weight: 900;

        @media (max-width: $large) {
          font-size: 2rem;
        }

        @media (max-width: $small) {
          margin-bottom: 2rem;
          font-size: 1.5rem;
        }
      }

      a {
        color: $blanc;

        &:hover {
          color: $rouge;
        }
      }

      .footer-link {
        margin: 0 1.5rem;
        padding: 1rem;
        font-weight: 300;
        letter-spacing: .1rem;
        text-transform: uppercase;

        @media (max-width: $large) {
          margin: 0 1rem;
          font-size: 1.4rem;
        }

        @media (max-width: $small) {
          font-size: 1.2rem;
        }

        &.underline:before {
          left: 1rem;
          right: 1rem;
          bottom: .3rem;
          height: .4rem;
          opacity: 0;
          transition: all .3s ease-in-out;
        }

        &:hover.underline:before, &.is-active.underline:before {
          bottom: .7rem;
          opacity: 1;
        }
      }
  	}

    .footer__content {
      padding-top: 8rem;
      padding-bottom: 6rem;

      @media (max-width: $small) {
        padding-top: 5rem;
        padding-bottom: 3rem;
      }
    }

    .footer__bottom p, .footer__bottom > a {
      margin: 0;
      padding: .3rem 1rem;
      font-size: 1.2rem;
      text-transform: uppercase;
      opacity: .7;

      @media (max-width: $small) {
        font-size: 1rem;
      }
    }


