
	// Reset

		input[type=number] {
			-moz-appearance: textfield;
		}

		input[type=number]::-webkit-inner-spin-button,
		input[type=number]::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		input:-webkit-autofill { // Background Yellow on Chrome
      -webkit-box-shadow: 0 0 0 3rem white inset;
      color: $black;
		}

		::-ms-clear {
	    display: none;
	  }

	  ::-ms-reveal {
	    display: none;
	  }

	  input[type=text], textarea {
	    -webkit-appearance: none;
	   	-moz-appearance: none;
	    appearance: none;
	  }

	// Placeholder

		::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: rgab($black, .7);
		}

		::-moz-placeholder { /* Firefox 19+ */
			color: rgab($black, .7);
		}

		:-ms-input-placeholder { /* IE 10+ */
			color: rgab($black, .7);
		}

		:-moz-placeholder { /* Firefox 18- */
			color: rgab($black, .7);
		}

  // Inputs

    textarea,
    input[type="text"],
    input[type="tel"],
    input[type="url"],
    input[type="file"],
    input[type="date"],
    input[type="email"],
    input[type="password"] {
      display: inline-block;
      width: 100%;
      padding: 1rem;
      font-size: 1.4rem;
      color: $black;
      line-height: inherit;
      vertical-align: middle;
      box-shadow: 0 0 0 .1rem rgba($noir, .3) inset;
      border-radius: 0;
      background-color: $blanc;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      transition: all .3s ease-in-out;

      &:hover,
      &:focus,
      &:active {
        outline: none;
        box-shadow: 0 0 0 .1rem rgba($noir, .8) inset;
        background-color: rgba($gris-clair, .3);
      }

      &.error {
        box-shadow: 0 0 0 .1rem $rouge inset;
      }
    }

  // Label

    label {
      margin-bottom: .7rem;
      text-transform: uppercase;
      color: $noir;
      font-weight: 300;
      letter-spacing: .1rem;
      font-size: 1.4rem;

      &.error {
        margin-top: .5rem;
        color: $red-500;
        font-weight: 400;
        font-size: 1.3rem;
      }
    }

  // Textarea

    textarea {
      height: 15rem;
      overflow: hidden;
      resize: none;
    }

  // Spécifique

    .form-group {
      margin-top: 2rem;
    }

    .btn--rouge {
      margin-top: 2rem;
      padding: 1rem;
      border: none;
      outline: none;
      font-size: 1.8rem;
      letter-spacing: .1rem;
      text-transform: uppercase;
      box-shadow: none;
      background-color: $rouge;
      transition: background-color .3s ease-in-out;

      svg {
        margin-right: .3rem;
        vertical-align: baseline;
        fill: $blanc;
      }

      &:hover {
        background-color: $anthracite;
      }
    }

    @media (max-width: $medium) {
      .grid-2-small-1 .form-group:first-of-type {
        margin-top: 0;
      }
    }

    #rc-imageselect, .g-recaptcha {
      @media (max-width: $tiny) {
        transform: scale(0.8) !important;
        transform-origin: 0 50% !important;
      }
    }
