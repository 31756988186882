
	// Fonts (Polices de caractère)

		// Poppins

			@font-face {
			    font-family: 'Poppins';
			    src: url('../fonts/poppins/poppins-regular.woff2') format('woff2'),
			         url('../fonts/poppins/poppins-regular.woff') format('woff'),
			         url('../fonts/poppins/poppins-regular.ttf') format('truetype'),
					 url('../font/poppins/poppins-regular.svg#PoppinsRegular') format('svg');
			    font-weight: 300;
			    font-style: normal;
			}

      @font-face {
          font-family: 'Poppins';
          src: url('../fonts/poppins/poppins-italic.woff2') format('woff2'),
               url('../fonts/poppins/poppins-italic.woff') format('woff'),
               url('../fonts/poppins/poppins-italic.ttf') format('truetype'),
           url('../font/poppins/poppins-italic.svg#PoppinsItalic') format('svg');
          font-weight: 300;
          font-style: italic;
      }

      @font-face {
          font-family: 'Poppins';
          src: url('../fonts/poppins/poppins-semibold.woff2') format('woff2'),
               url('../fonts/poppins/poppins-semibold.woff') format('woff'),
               url('../fonts/poppins/poppins-semibold.ttf') format('truetype'),
           url('../font/poppins/poppins-semibold.svg#PoppinsSemiBold') format('svg');
          font-weight: 700;
          font-style: normal;
      }

      @font-face {
          font-family: 'Poppins';
          src: url('../fonts/poppins/poppins-black.woff2') format('woff2'),
               url('../fonts/poppins/poppins-black.woff') format('woff'),
               url('../fonts/poppins/poppins-black.ttf') format('truetype'),
           url('../font/poppins/poppins-black.svg#PoppinsBlack') format('svg');
          font-weight: 900;
          font-style: normal;
      }

