// Style pages oeuvres

  // Style page catégories

    .oeuvres-content-intro .texte-wrapper {
      margin-top: 4rem;

      @media (max-width: $medium) {
        margin-top: 2rem;
      }

      p:first-of-type {
        margin-top: 1rem;
      }

      p:last-of-type, blockquote:last-of-type {
        margin-bottom: 0;
      }
    }

    .oeuvres-content {
      margin-bottom: 10rem;
      padding: 0 1rem;

      @media (max-width: $extra-large) {
        margin-bottom: 7rem;
      }

      @media (max-width: $medium) {
        margin-bottom: 4rem;
      }

      @media (max-width: $tiny) {
        margin-bottom: 3rem;
      }

      & > .flex-container {
        width: 100%;
      }
    }

    .oeuvre {
      position: relative;
      width: 25%;
      padding: 1rem;

      @media (max-width: $xx-large) {
        width: 33.33%;
      }

      @media (max-width: $large) {
        width: 50%;
      }

      @media (max-width: $small) {
        width: 100%;
      }

      &:hover .oeuve-texte-wrapper {
        opacity: 1;
      }

      .oeuvre-link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
      }

      .oeuve-texte-wrapper {
        position: absolute;
        top: 1rem;
        left: 1rem;
        right: 1rem;
        bottom: 1rem;
        padding: 1.2rem 1.5rem;
        opacity: 0;
        box-shadow: inset 0 0 0 .3rem $rouge;
        background-color: rgba($blanc, .8);
        transition: opacity .2s ease-in-out;
        z-index: 1;

        @media (max-width: $large) {
          h2 {
            font-size: 2.3rem;
          }

          h3 {
            font-size: 2rem;
          }
        }

        h3 {
          margin: -.5rem 0 0;
        }
      }

      .oeuvre-pic {
        width: 100%;
      }
    }

    // Style pages Produits

    .oeuvre-content {
      .titles-wrapper {
        font-size: 3.8rem;

        @media (max-width: $large) {
          font-size: 2.8rem;
        }

        @media (max-width: $small) {
          font-size: 1.8rem;
        }

        h1, h2 {
          display: inline;
          margin: 0;
        }

        & > span {
          margin: 0 1.5rem;
        }

        h1 {
          text-transform: unset;
          letter-spacing: 0;
          font-size: 3.8rem;
          font-weight: 300;
          color: $anthracite;

          @media (max-width: $large) {
            font-size: 3rem;
          }

          @media (max-width: $small) {
            font-size: 2.3rem;
          }
        }

        h2 {
          text-transform: uppercase;

          span {
            padding: 0 1rem;
            font-weight: 700;
            font-size: 3rem;
            color: $blanc;
            background-color: $rouge;

            @media (max-width: $large) {
              font-size: 2.5rem;
            }

            @media (max-width: $small) {
              font-size: 2rem;
            }
          }
        }
      }

      .fil-ariane {
        margin: 2rem 0 0;
        text-transform: uppercase;
        font-size: 1.2rem;

        a {
          padding: 0 .5rem;
          color: rgba($anthracite, .7);

          &:hover {
            color: $noir;
          }

          &:first-of-type {
            padding-left: 0;
          }
        }

        span {
          padding-left: .5rem;
          color: $rouge;
        }
      }

      .flex-container {
        margin-top: 6rem;

        @media (max-width: $medium) {
          justify-content: center;
          margin-top: 3rem;
        }

        @media (max-width: $tiny) {
          margin-top: 2rem;
        }
      }

      .texte-wrapper {
        width: calc(50% + 4rem);

        @media (max-width: $medium) {
          width: 100%;
          margin-bottom: 3rem;
        }

        .texte {
          width: 100%;
          max-width: 56rem;
          margin: 0 0 0 auto;
          padding: 0 4rem 0 2rem;
        }

        .description {
          margin-bottom: 4rem;

          @media (max-width: $medium) {
            margin-bottom: 3rem;
          }

          @media (max-width: $tiny) {
            margin-bottom: 2rem;
          }
        }

        blockquote {
          padding-left: 4rem;
          font-size: 1.8rem;
          z-index: -1;

          @media (max-width: $large) {
            font-size: 1.6rem;
          }

          @media (max-width: $medium) {
            padding-left: 3rem;
          }

          @media (max-width: $tiny) {
            padding-left: 2rem;
          }

          &:before {
            top: 0;
            font-size: 12rem;

            @media (max-width: $medium) {
              font-size: 10rem;
            }

            @media (max-width: $tiny) {
              left: -1rem;
              font-size: 6rem;
            }
          }
        }

        .info {
          margin: 2rem 0;
          text-transform: uppercase;
          font-weight: 900;
          font-size: 1.4rem;

          svg {
            margin: 0 .2rem 0 -.3rem;
            fill: $anthracite;
          }
        }

        .link-facebook {
          display: block;
          margin-top: 4rem;
          font-weight: 700;
          text-transform: uppercase;
          color: $rouge;
          transition: opacity .3s ease-in-out;

          @media (max-width: $medium) {
            margin-top: 3rem;
          }

          @media (max-width: $tiny) {
            margin-top: 2rem;
          }

          &:hover {
            opacity: .8;
          }

          svg {
            margin-right: 1rem;
            fill: $rouge;
          }

          span {
            vertical-align: top;
          }
        }
      }

      .pics-wrapper {
        width: calc(50% - 4rem);
        padding-left: 4rem;

        @media (max-width: $medium) {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          margin: -1rem 0;
          padding: 0;
        }

        .oeuvre-pic-big {
          display: inline-block;
          outline: none;
          margin-bottom: 2rem;

          @media (max-width: $medium) {
            width: 50%;
            margin: 0;
            padding: 1rem;
          }

          @media (max-width: $tiny) {
            width: 100%;
            padding: 1rem 0;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        .oeuvre-single-pic {
          width: 100%;
        }
      }
    }
