
	// Styles Communs

		html {
			display: flex;
			flex-direction: column;
		}

		body {
			min-height: 100vh;
			display: flex;
			flex-direction: column;
		}

		main {
			flex: 1 1 auto;
      margin-top: 10rem;

      @media (max-width: $small) {
        margin-top: 6.4rem;
      }
		}

		.is-fixed {
			width: 100%;
			height: 100%;
			position: fixed;
			overflow: hidden;
		}

    a {
      text-decoration: none;
      cursor: pointer;
      color: $black;
      transition: color .3s ease-in-out;

      &.underline {
        display: inline-block;
        position: relative;

        &:before {
          position: absolute;
          left: 0;
          right: 0;
          bottom: .1rem;
          height: .2rem;
          content: '';
          background-color: $rouge;
          transition: background-color .3s ease-in-out;
        }

        &:hover {
          color: $rouge;

          &:before {
            background-color: $black;
          }
        }
      }
    }

    b, strong {
      font-weight: 700;
    }

  // Titres

    h1 {
      margin: 0 0 3rem -2rem;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 4.5rem;
      letter-spacing: .3rem;
      color: $blanc;

      @media (max-width: $large) {
        font-size: 3.5rem;
      }

      @media (max-width: $small) {
        font-size: 2.5rem;
      }

      span {
        padding: 0 1rem;
        background-color: $anthracite;
      }
    }

    h2 {
      margin: 0;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 3rem;
      letter-spacing: .1rem;
      line-height: 3.5rem;
      color: $noir;
      transition: color .3s ease-in-out;

      @media (max-width: $large) {
        font-size: 2.5rem;
      }

      @media (max-width: $small) {
        font-size: 2rem;
      }
    }

    blockquote {
      padding-left: 8rem;
      font-style: italic;
      font-size: 2rem;

      @media (max-width: $large) {
        font-size: 1.8rem;
      }

      @media (max-width: $medium) {
        padding-left: 4rem;
      }

      @media (max-width: $tiny) {
        padding-left: 2rem;
      }

      &:before {
        left: -2rem;
        top: -2rem;
        font-size: 19rem;
        color: #eaeaea;

        @media (max-width: $medium) {
          font-size: 12rem;
          height: 5rem;
        }

        @media (max-width: $tiny) {
          top: -1rem;
          font-size: 8rem;
          height: 3rem;
        }
      }
    }

    ul li {
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

  // Animations
    @keyframes float {
      0% {
        bottom: 1rem;
      }
      50% {
        bottom: .6rem;
      }
      100% {
        bottom: 1rem;
      }
    }

    @media (max-width: $medium) {
      @keyframes float {
        0% {
          bottom: -.4rem;
        }
        50% {
          bottom: -.8rem;
        }
        100% {
          bottom: -.4rem;
        }
      }
    }

  // SVG

    svg, path {
      transition: fill .3s ease-in-out;
    }

    .fill-rouge {
      fill: $rouge;
    }

    .fill-noir {
      fill: $noir;
    }

  // Conteneurs

      %container {
        position: relative;
        width: 100%;
        margin: 0 auto;
        padding: 0 2rem;
      }

      .container-tiny {
        max-width: 70rem;
        @extend %container;
      }

      .container-small {
        max-width: 90rem;
        @extend %container;
      }

      .container {
        max-width: 120rem;
        @extend %container;
      }

      .container-large {
        max-width: 140rem;
        @extend %container;
      }

      .p-section {
        padding-top: 10rem;
        padding-bottom: 10rem;

        @media (max-width: $extra-large) {
          padding-top: 7rem;
          padding-bottom: 7rem;
        }

        @media (max-width: $medium) {
          padding-top: 4rem;
          padding-bottom: 4rem;
        }

        @media (max-width: $tiny) {
          padding-top: 3rem;
          padding-bottom: 3rem;
        }
      }

  // Styles graphiques

    .noir {
      color: $gris-clair;
      background-color: $noir;

      a {
        color: $white;

        &:hover {
          color: $rouge;

          &:before {
            background-color: $white;
          }
        }
      }
    }

    .clair {
      background-color: rgba($gris-clair, .2);
    }

  // Classes Utilitaires

    .align-items-center {
      align-items: center;
    }

    .justify-content-center {
      justify-content: center;
    }

  // Style pagination

    .pagination {
      width: 100%;
      padding: 4rem 0 0;
      text-align: center;

      @media (max-width: $medium) {
        padding-top: 3rem;
      }

      @media (max-width: $tiny) {
        padding-top: 2rem;
      }

      span, a {
        display: inline-block;
        width: 4rem;
        height: 4rem;
        color: $blanc;

        @media (max-width: $tiny) {
          width: 2.5rem;
          height: 2.5rem;
          font-size: 1.3rem;
        }
      }

      span {
        margin: 0 .25rem;
      }

      .current {
        padding: 1rem;
        background-color: $rouge;

        @media (max-width: $tiny) {
          padding: .5rem;
        }
      }

      a {
        padding: 1rem;
        text-decoration: none;
        background-color: $noir;
        transition: background-color .3s ease-in-out;

        @media (max-width: $tiny) {
          padding: .5rem;
        }

        &:hover {
          background-color: $gris-med;
        }
      }
    }

  // Style pages Mentions légales, Plan du site et 404

    .mentions-legales h2 {
      margin: 2rem 0 1rem;
    }

    .plan-du-site {
      li {
        list-style-type: none;
      }

      a:hover {
        color: $rouge;
      }

      .page--sitemap__second, .page--sitemap__third {
        margin-top: 1rem;
      }

      .page--sitemap__first {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 2.2rem;
        letter-spacing: .1rem;

        @media (max-width: $medium) {
          padding-left: 0;
          font-size: 1.6rem;
        }
      }

      .page--sitemap__second {
        text-transform: uppercase;
        font-weight: 300;
        font-size: 2rem;

        @media (max-width: $medium) {
          padding-left: 1rem;
          font-size: 1.4rem;
        }
      }

      .page--sitemap__third {
        display: flex;
        flex-wrap: wrap;
        font-size: 1.6rem;
        text-transform: none;
        letter-spacing: 0;

        @media (max-width: $medium) {
          padding-left: 2rem;
          font-size: 1.2rem;
        }

        li:not(:last-of-type) {
          padding-right: 1rem;

          @media (max-width: $medium) {
            padding-right: .5rem;
          }

          a {
            padding-right: .5rem;

            @media (max-width: $medium) {
              padding-right: .25rem;
            }
          }

          &:after {
            content: '—';
          }
        }
      }
    }

    .erreur-404 {
      display: flex;
      align-items: center;
      height: 100%;
      min-height: calc(100vh - 39.5rem);
      color: $gris-clair;
      background-color: $rouge;

      @media (max-width: $large) {
        min-height: calc(100vh - 38.5rem);
      }

      h1 {
        margin: 0 0 3rem 0;
        font-size: 9rem;

        @media (max-width: $large) {
          font-size: 5rem;
        }

        @media (max-width: $small) {
          margin-bottom: 2rem;
          font-size: 3rem;
        }
      }

      a {
        color: $blanc;

        &:hover {
          color: $noir;
        }
      }
    }
