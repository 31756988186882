
  // Navigation

    header {
      position: fixed;
      width: 100%;
      z-index: 5;

      &.is-mobile-nav-open .links-wrapper {
        pointer-events: auto;
        opacity: 1;
      }
    }

    nav {
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 10rem;
      padding: 1rem;
      background-color: $blanc;

      @media (max-width: $small) {
        height: 6.4rem;
      }
    }

    .header__logo {
      @media (min-width: $small + 1) {
        padding: 1rem;
      }

      .logo-bernard-bavaud {
        width: 16rem;
        height: 5.7rem;
        vertical-align: middle;

        @media (max-width: $small) {
          width: 12rem;
          height: 4.2rem;
        }
      }

      &:hover .logo-bernard-bavaud {
        fill: $anthracite;
      }
    }

    .links-wrapper {
      align-items: center;

      @media (max-width: $large) {
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: calc(100vh - 10rem);
        padding: 1rem;
        text-align: center;
        pointer-events: none;
        background-color: rgba($noir, .9);
        opacity: 0;
        transition: opacity .3s ease-in-out;
      }

      @media (max-width: $small) {
        height: calc(100vh - 6.4rem);
      }
    }

    .categories-drop-down {
      position: relative;

      .nav-link {
        z-index: 2;
      }

      @media (min-width: $large + 1) {
        &:hover .nav-categories-wrapper {
          top: 6rem;
          pointer-events: auto;
          opacity: 1;

          @media (max-width: $extra-large) {
            top: 4rem;
          }
        }
      }
    }

    .nav-categories-wrapper {
      flex-direction: column;

      @media (min-width: $large + 1) {
        align-items: flex-start;
        position: absolute;
        top: 7rem;
        left: 1rem;
        max-width: 30rem;
        width: 300%;
        pointer-events: none;
        opacity: 0;
        z-index: 1;
        transition: all .3s ease-in-out;
      }

      @media (max-width: $extra-large) {
        top: 5rem;
      }

      @media (max-width: $large) {
        align-items: center;
      }
    }

    .nav-link.nav-sub-link {
      padding: 1rem;
      font-weight: 700;
      letter-spacing: .2rem;
      text-transform: uppercase;

      @media (min-width: $large + 1) {
        span {
          padding: 0 .3rem;
          color: $gris-clair;
          background-color: $anthracite;
          transition: all .3s ease-in-out;
        }

        &:hover span {
          color: $blanc;
          background-color: $rouge;
        }

        &.underline:before {
          content: none;
        }
      }

      @media (max-width: $extra-large) {
        padding: 1rem 0;
      }

      @media (max-width: $large) {
        padding: 1rem;
      }

      @media (max-width: $tiny) {
        padding: .5rem 1rem;
      }
    }

    .nav-link {
      margin-top: .4rem;
      padding: 2rem;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: .2rem;
      font-weight: 700;

      @media (max-width: $extra-large) {
        padding: 1rem;
        font-size: 1.5rem;
      }

      @media (max-width: $large) {
        color: $blanc;

        &.is-active {
          color: $rouge;
        }
      }

      @media (max-width: $tiny) {
        margin: 0;
        padding: .5rem 1rem;
        font-size: 1.4rem;
      }

      &.underline:before {
        left: 2rem;
        right: 2rem;
        bottom: 1.5rem;
        height: .4rem;
        opacity: 0;
        transition: all .3s ease-in-out;

        @media (max-width: $extra-large) {
          left: 1rem;
          right: 1rem;
          bottom: .5rem;
        }

        @media (max-width: $small) {
          height: .2rem;
        }

        @media (max-width: $tiny) {
          bottom: -.2rem;
        }
      }

      &:hover.underline:before, &.is-active.underline:before {
        bottom: 2rem;
        opacity: 1;

        @media (max-width: $extra-large) {
          bottom: 1rem;
        }

        @media (max-width: $large) {
          background-color: $blanc;
        }

        @media (max-width: $tiny) {
          bottom: .4rem;
        }
      }
    }

    .hamburger {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      outline: none;

      @media (min-width: $large + 1) {
        display: none;
      }

      .hamburger-label {
        margin-right: 1rem;
        text-transform: uppercase;
        font-weight: 400;
        color: $noir;
      }
    }

    // Style noir

    body:not(.accueil) nav {
      background-color: $noir;

      .header__logo {
        .logo-bernard-bavaud .fill-noir {
          fill: $blanc;
        }

        &:hover .logo-bernard-bavaud .fill-noir {
          fill: $gris-clair;
        }
      }

      .nav-link {
        color: $blanc;

        &.is-active {
          color: $blanc;

          &.underline:before {
            background-color: $rouge;
          }
        }

        &:hover {
          color: $rouge;

          &.underline:before {
            background-color: $blanc;
          }
        }
      }

      .hamburger {
        .hamburger-label {
          color: $blanc;
        }

        .hamburger-box .hamburger-inner, .hamburger-box .hamburger-inner:before, .hamburger-box .hamburger-inner:after {
          background-color: $blanc;
        }
      }
    }
