// Style actualités

  .actualite-date {
    position: relative;
    margin: 2rem 0 3rem;
    text-transform: uppercase;
    font-size: 1.2rem;

    span {
      padding: .2rem .5rem 0;
      color: $blanc;
      background-color: $gris-med;
      transition: all .3s ease-in-out;
    }
  }

  // Style page actualités

    .body-actualites main {
      overflow: hidden;
    }

    .actualites {
      .actualite-single {
        position: relative;
        margin-bottom: 4rem;
        transition: all .3s ease-in-out;

        @media (max-width: $extra-large) {
          align-items: center;
        }

        @media (min-width: $large + 1) {
          &:nth-of-type(even) .actualite-single-content {
            order: 1;
          }
        }

        .actualite-date {
          margin: 2rem 0 0;

          @media (max-width: $small) {
            margin-top: .5rem;
          }
        }

        .txt-wrapper {
          margin-top: 3rem;

          @media (max-width: $large) {
            margin-top: 2rem;
          }

          @media (max-width: $small) {
            margin-top: 1rem;
          }
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        &:hover {
          color: $gris-clair;
          background-color: $rouge;

          h2, .fake-btn {
            color: $blanc;

            &:after {
              background-color: rgba($blanc, .3);
            }
          }

          .actualite-date span {
            color: $rouge;
            background-color: $blanc;
          }
        }
      }

      .actualite-single-link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .actualite-single-img {
        width: calc(50% + 4rem);

        @media (max-width: $large) {
          width: 100%;

          img {
            width: 100%;
          }
        }
      }

      .actualite-single-content {
        width: calc(50% - 4rem);
        padding: 4rem;

        @media (max-width: $extra-large) {
          padding: 2rem;
        }

        @media (max-width: $large) {
          width: 100%;
          padding: 2rem 2rem 3rem;
        }
      }

      h2, .actualite-single-date, .fake-btn {
        transition: color .3s ease-in-out;
      }

      h2 {
        position: relative;

        &:before {
          position: absolute;
          left: -5rem;
          top: 50%;
          content: '—';
          transform: translateY(-50%);

          @media (max-width: $extra-large) {
            left: -3rem;
          }
        }
      }

      .fake-btn {
        display: block;
        margin-top: 4rem;
        text-transform: uppercase;
        font-weight: 700;

        @media (max-width: $large) {
          margin-top: 2rem;
        }
      }
    }

  // Style page actualité

    .actualite {
      display: flex;
      flex-direction: column;
      min-height: calc(100vh - 39.5rem);

      h1 {
        position: relative;
        margin: 0;
        letter-spacing: 0;
        text-transform: none;
        color: $anthracite;

        &:before {
          position: absolute;
          left: -5rem;
          top: 50%;
          content: '—';
          transform: translateY(-50%);

          @media (max-width: $extra-large) {
            content: none;
          }
        }
      }

      a {
        color: $rouge;

        &:hover {
          color: $gris-med;
        }
      }

      .container-small {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-top: 2rem;

        @media (max-width: $medium) {
          margin-top: 1rem;
        }

        @media (max-width: $tiny) {
          margin-top: 0;
        }
      }

      .actualite-date span {
        background-color: $rouge;
      }

      img {
        width: 100%;
        margin: 2rem 0;
      }

      .buttons-wrapper {
        justify-content: flex-end;
        align-items: center;
        margin: auto 0 0;

        @media (max-width: $tiny) {
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
        }
      }

      .link-facebook, .basic-btn {
        margin-top: 4rem;
        font-size: 1.8rem;
        letter-spacing: .1rem;
        text-transform: uppercase;

        @media (max-width: $medium) {
          margin-top: 2rem;
        }

        @media (max-width: $small) {
          font-size: 1.4rem;
        }

        @media (max-width: $tiny) {
          margin-top: 0;
        }
      }

      .link-facebook {
        align-items: center;
        margin-right: 2rem;
        color: $rouge;
        transition: opacity .3s ease-in-out;

        @media (max-width: $tiny) {
          margin: 0 0 2rem 0;
        }

        svg {
          margin: 0 .5rem .1rem 0;
          fill: $rouge;
        }

        &:hover {
          opacity: .8;
        }
      }

      .basic-btn {
        svg {
          margin-right: .3rem;
          vertical-align: baseline;
          fill: $blanc;
        }

        span {
          padding: .2rem .7rem;
          color: $blanc;
          background-color: $noir;
          transition: background-color .3s ease-in-out;
        }

        &:hover span {
          background-color: $rouge;
        }
      }
    }
